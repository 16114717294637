import clsx from 'clsx';
import React from 'react';

export type CardTitleProps = {
	className?: string;
	children: React.ReactNode;
};

export const CardTitle = ({ className, children }: CardTitleProps) => {
	return <h5 className={clsx('card-title', className)}>{children}</h5>;
};
