import React from 'react';

import { useAppPrefix } from '@/hooks';

import { Header } from './Header';

interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
	const currentAppPrefix = useAppPrefix();

	return (
		<div className="nk-main">
			<div className={`nk-wrap${currentAppPrefix === 'inbox' ? ' p-0' : ''}`}>
				<Header />
				{children}
			</div>
		</div>
	);
};
