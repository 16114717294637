const storagePrefix = '@neotic/neo_suite_';

const storage = {
	getToken: () => {
		return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
	},
	setToken: (token: string) => {
		window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
	},
	clearToken: () => {
		window.localStorage.removeItem(`${storagePrefix}token`);
	},
	hasToken: () => {
		return null != storage.getToken();
	},
};

export default storage;
