import React from 'react';
import ReactPaginate from 'react-paginate';

type PaginateProps = {
	page: number;
	pageCount: number;
	handlePageClick: (data: { selected: number }) => void;
};

export const Paginate = ({ page, pageCount, handlePageClick }: PaginateProps) => {
	return (
		<div className="card-inner">
			<div className="nk-block-between-md g-3">
				<ReactPaginate
					initialPage={page - 1}
					pageCount={pageCount}
					onPageChange={handlePageClick}
					activeClassName="active"
					pageClassName="page-item"
					pageLinkClassName="page-link"
					containerClassName="pagination justify-content-center"
					breakClassName="page-item"
					breakLinkClassName="page-link"
					breakLabel={<em className="icon ni ni-more-h"></em>}
					nextClassName="page-item"
					nextLinkClassName="page-link"
					previousClassName="page-item"
					previousLinkClassName="page-link"
				/>
			</div>
		</div>
	);
};
