import clsx from 'clsx';
import React, { ReactNode } from 'react';

type ButtonProps = {
	type?: 'button' | 'submit' | 'reset' | undefined;
	disabled?: boolean;
	size?: 'sm' | 'md' | 'lg';
	variant?: 'primary' | 'success' | 'danger' | 'warning' | 'default';
	className?: string;
	title?: string;
	children: ReactNode;
	onClick?: () => void;
};

export const Button: React.FC<ButtonProps> = ({
	type,
	disabled,
	className,
	title,
	onClick,
	variant = 'primary',
	size = 'md',
	children,
}: ButtonProps) => {
	return (
		<button
			type={type}
			disabled={disabled}
			onClick={onClick}
			title={title}
			className={clsx(`btn btn-${size} btn-${variant}`, className)}
		>
			{children}
		</button>
	);
};
