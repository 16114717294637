import {
	faChartSimple,
	faCog,
	faFileInvoice,
	faFileInvoiceDollar,
	faHomeLg,
	faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonLink, Link } from '@/components/Elements';
import { SidebarWrapper } from '@/components/Layouts';

import { INVOICING_ROUTES } from '../routes';

export const Sidebar = () => {
	return (
		<SidebarWrapper>
			<li className="nk-menu-item">
				<Link to={INVOICING_ROUTES.dashboard} className="nk-menu-link">
					<span className="nk-menu-icon">
						<FontAwesomeIcon icon={faHomeLg} />
					</span>
					<span className="nk-menu-text">Tableau de bord</span>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={INVOICING_ROUTES.invoices} className="nk-menu-link">
					<span className="nk-menu-icon">
						<FontAwesomeIcon icon={faFileInvoiceDollar} />
					</span>
					<span className="nk-menu-text">Factures</span>
					<ButtonLink to={INVOICING_ROUTES.invoice_new}>
						<span className="nk-menu-icon">
							<FontAwesomeIcon icon={faPlusSquare} />
						</span>
					</ButtonLink>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={INVOICING_ROUTES.estimates} className="nk-menu-link">
					<span className="nk-menu-icon">
						<FontAwesomeIcon icon={faFileInvoice} />
					</span>
					<span className="nk-menu-text">Devis</span>
					<ButtonLink to={INVOICING_ROUTES.estimate_new}>
						<span className="nk-menu-icon">
							<FontAwesomeIcon icon={faPlusSquare} />
						</span>
					</ButtonLink>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={INVOICING_ROUTES.statistics} className="nk-menu-link">
					<span className="nk-menu-icon">
						<FontAwesomeIcon icon={faChartSimple} />
					</span>
					<span className="nk-menu-text">Statistiques</span>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={INVOICING_ROUTES.settings} className="nk-menu-link">
					<span className="nk-menu-icon">
						<FontAwesomeIcon icon={faCog} />
					</span>
					<span className="nk-menu-text">Paramètres</span>
				</Link>
			</li>
		</SidebarWrapper>
	);
};
