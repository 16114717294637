import clsx from 'clsx';
import React from 'react';

type BlockTextProps = {
	className?: string;
	children: React.ReactNode;
};

export const BlockText = ({ className, children }: BlockTextProps) => {
	return <div className={clsx('nk-block-des', className)}>{children}</div>;
};
