import React from 'react';

import { ContentLayout } from '@/components/Layouts';

export const Statistics = () => {
	return (
		<ContentLayout>
			<h1 className="h3">Statistics</h1>
		</ContentLayout>
	);
};
