import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { NOTIFICATION_DELAY } from '@/config';

import { IconError, IconInfo, IconSuccess, IconWarning } from '../Icons';

import { ToastIconWrapper } from './ToastIconWrapper';

const icons = {
	info: <IconInfo />,
	primary: undefined,
	success: <IconSuccess />,
	warning: <IconWarning />,
	error: <IconError />,
};

export type NotificationProps = {
	notification: {
		id: string;
		type: keyof typeof icons;
		title: string;
		message?: string;
	};
	onDismiss: (id: string) => void;
};

export const Notification = ({
	notification: { id, type, title, message },
	onDismiss,
}: NotificationProps) => {
	const [show, setShow] = useState(true);

	const onClose = () => {
		onDismiss(id);
		setShow(false);
	};

	useEffect(() => {
		setTimeout(() => {
			onClose();
		}, NOTIFICATION_DELAY);
	});

	if (!show) return null;

	return (
		<div className={clsx('toastr', `toast-${type}`)} aria-live="polite">
			<span
				role={'button'}
				tabIndex={-1}
				onClick={onClose}
				className="btn-trigger toast-close-button"
				onKeyDown={() => onClose()}
			>
				Close
			</span>
			<div className="toast-message">
				<ToastIconWrapper>{icons[type]}</ToastIconWrapper>
				<div className="toastr-text">
					<h6>{title}</h6>
					<p>{message}</p>
				</div>
			</div>
		</div>
	);
};
