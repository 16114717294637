import React from 'react';

type BadgeProps = {
	text: string;
	type: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger';
};

export const Badge = ({ type, text }: BadgeProps) => {
	return <span className={`badge badge-${type}`}>{text}</span>;
};
