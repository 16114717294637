import React from 'react';
import * as z from 'zod';

import { Card, CardTitle } from '@/components/Card';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField, SelectField, TextAreaField } from '@/components/Form';
import { useAllContactNames } from '@/modules/contacts';
import { useCreateItem } from '@/modules/shared/hooks';

import { useTicketTypes } from '../features/ticket-types';
import { CreateTicketDTO, Ticket } from '../types';

import { CreateTicketIcon } from './CreateTicketIcon';

const schema = z.object({
	title: z.string().min(10, 'Ce champs obligatoire (10 caracètres min)'),
	type: z.string().min(1, 'Ce champs est obligatoire.'),
	description: z.string().min(25, 'Ce champs obligatoire (25 caracètres min)'),
	assignedTo: z.string().optional(),
	labels: z.array(z.string()).optional(),
	customer: z.string().min(1, 'Ce champs est obligatoire.'),
});

type TicketFormProps = {
	ticket?: Ticket;
	isEditing?: boolean;

	onSuccess: (ticket: Ticket) => void;
};

export const TicketForm = ({ onSuccess }: TicketFormProps) => {
	const ticketTypesQuery = useTicketTypes();
	const contactsQuery = useAllContactNames();

	const createTicketMutation = useCreateItem<Ticket, CreateTicketDTO>({
		key: 'tickets',
		successMessage: 'Ticket crée.',
	});

	const handleCreate = async (values: CreateTicketDTO) => {
		const ticket = await createTicketMutation.mutateAsync({
			data: values,
			path: '/tickets',
		});
		onSuccess(ticket);
	};

	return (
		<Card>
			<CardTitle>Formulaire</CardTitle>
			<p>
				<em className="text-danger">*</em> Champs obligatoires.
			</p>
			<Form<CreateTicketDTO, typeof schema>
				onSubmit={handleCreate}
				schema={schema}
				className="gy-3 form-settings"
			>
				{({ register, formState }) => (
					<>
						<InputField
							type="text"
							label="Titre"
							placeholder="Entrez le titre du ticke"
							error={formState.errors['title']}
							registration={register('title')}
							group={false}
						/>
						<SelectField
							options={
								ticketTypesQuery.data
									? ticketTypesQuery.data['hydra:member'].map((type) => ({
											label: type.name,
											value: type.iri,
									  }))
									: []
							}
							placeholder="Sélectionnez le type de ticket"
							error={formState.errors['type']}
							registration={register('type')}
							group={false}
						/>
						<SelectField
							options={
								contactsQuery.data
									? contactsQuery.data.map((customer) => ({
											label: customer.name,
											value: customer.id,
									  }))
									: []
							}
							placeholder="Sélectionnez un client"
							error={formState.errors['customer']}
							registration={register('customer')}
							group={false}
						/>
						<TextAreaField
							label="Description"
							placeholder="Entrez la description du ticket"
							error={formState.errors['description']}
							registration={register('description')}
						/>
						<Button
							disabled={createTicketMutation.isLoading}
							size="lg"
							variant="primary"
							type="submit"
							className="btn btn-block"
						>
							{createTicketMutation.isLoading ? <Spinner /> : <CreateTicketIcon />}
							<span>Créer le ticket</span>
						</Button>
					</>
				)}
			</Form>
		</Card>
	);
};
