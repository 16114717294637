import clsx from 'clsx';
import React from 'react';

type BlockTitleProps = {
	className?: string;
	children: React.ReactNode;
};

export const BlockTitle = ({ className, children }: BlockTitleProps) => {
	return <h3 className={clsx('nk-block-title page-title fw-medium', className)}>{children}</h3>;
};
