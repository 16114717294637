import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PartialCollectionResults } from '@/types';

import { TicketType } from '../types';

export const getTicketTypes = (): Promise<PartialCollectionResults<TicketType>> => {
	return axios.get('/ticket/types');
};

type QueryFnType = typeof getTicketTypes;

type UseTicketOptions = {
	config?: QueryConfig<QueryFnType>;
};

export const useTicketTypes = ({ config = {} }: UseTicketOptions = {}) => {
	return useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: ['ticketTypes'],
		queryFn: () => getTicketTypes(),
	});
};
