import React from 'react';

import {
	BlockHeadBetween,
	BlockHeadContent,
	BlockText,
	BlockTitle,
	BlockToolItem,
	BlockTools,
} from '@/components/Block';
import { Paginate, Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layouts';
import { useListing } from '@/modules/shared/hooks';

import { TicketsTable } from '../components';
import { Ticket } from '../types';

import { TICKET_ROUTES } from '.';

export const Tickets = () => {
	const {
		data: tickets,
		isLoading,
		page,
		pageCount,
		handlePageClick,
	} = useListing<Ticket>('/tickets');

	return (
		<ContentLayout title="Tickets">
			<BlockHeadBetween>
				<BlockHeadContent>
					<BlockTitle>
						<span className="nk-menu-icon">
							<em className="icon ni ni-ticket-fill"></em>
						</span>
						Tickets
					</BlockTitle>
					<BlockText className="text-soft">
						{tickets?.['hydra:totalItems'] && (
							<p>Vous avez au total {tickets['hydra:totalItems']} tickets.</p>
						)}
					</BlockText>
				</BlockHeadContent>
				<BlockHeadContent>
					<BlockTools>
						<BlockToolItem to={TICKET_ROUTES.create} className="btn btn-primary">
							<em className="icon ni ni-ticket-plus"></em>
							<span>Créer un ticket</span>
						</BlockToolItem>
					</BlockTools>
				</BlockHeadContent>
			</BlockHeadBetween>
			<div className="nk-block">
				<div className="card card-bordered">
					<TicketsTable tickets={tickets?.['hydra:member'] || []} />
					<div className="w-100">
						<div className="text-center" style={{ height: 75, paddingTop: 25 }}>
							{isLoading && <Spinner />}
							{!isLoading && !tickets?.['hydra:totalItems'] && (
								<p className="text-body">Cette liste est vide.</p>
							)}
						</div>
					</div>
					<Paginate {...{ page, pageCount, handlePageClick }} />
				</div>
			</div>
		</ContentLayout>
	);
};
