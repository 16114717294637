import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route, Routes } from 'react-router-dom';

import { ErrorFallback, Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { AppLayout } from '@/components/Layouts';

import { Sidebar } from '../components/Sidebar';

import { CreateTicket } from './CreateTicket';
import { Dashboard } from './Dashboard';
import { Ticket } from './Ticket';
import { Tickets } from './Tickets';

const prefix = '/app/tickets';

export const TICKET_ROUTES = {
	dashboard: prefix,
	list: `${prefix}/list`,
	create: `${prefix}/create`,
	show: `${prefix}/list/:id`,
	edit: `${prefix}/list/:id/edit`,
};

const App = () => {
	return (
		<Suspense fallback={<Spinner />}>
			<Head title="Gestion de tickets" />
			<AppLayout>
				<Sidebar />
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Outlet />
				</ErrorBoundary>
			</AppLayout>
		</Suspense>
	);
};

export const TicketsRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<App />}>
				<Route path="list" element={<Tickets />} />
				<Route path="create" element={<CreateTicket />} />
				<Route path="list/:id" element={<Ticket />} />
				<Route path="" element={<Dashboard />} />
			</Route>
		</Routes>
	);
};
