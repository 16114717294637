import React, { useState } from 'react';

import { ButtonLink } from '@/components/Elements';

import { useDeleteNote } from '../api/deleteNote';
import { ContactNote as ContactNoteType } from '../types';

import ContactNote from './ContactNote';
import ContactNoteForm from './ContactNoteForm';

interface ContactNotesContainerProps {
	notes?: ContactNoteType[];
	onAddNote: (note: ContactNoteType) => void;
	onNoteDeleted: (id: string) => void;
	contactId?: string;
}

function ContactNotesContainer({
	notes,
	contactId,
	onNoteDeleted,
	onAddNote,
}: ContactNotesContainerProps) {
	const [showForm, setShowForm] = useState(false);
	const deleteNoteMutation = useDeleteNote();

	const onDelete = (noteId: string) => {
		deleteNoteMutation.mutateAsync({ noteId }).then(() => {
			onNoteDeleted(noteId);
		});
	};

	return (
		<div className="nk-block">
			<div className="nk-block-head nk-block-head-sm nk-block-between">
				<h5 className="title">
					<em className="icon ni ni-notes"></em> Notes
				</h5>
				<ButtonLink onClick={() => setShowForm(true)}>
					<em className="icon ni ni-edit"></em> Ajouter une note
				</ButtonLink>
			</div>
			{contactId && (
				<ContactNoteForm
					onAdd={onAddNote}
					contact={contactId}
					showForm={showForm}
					onHideForm={() => setShowForm(false)}
				/>
			)}
			<div className="bq-notes">
				{notes?.map((note) => (
					<ContactNote onDelete={onDelete} key={note.id} note={note} />
				))}
			</div>
		</div>
	);
}

export default ContactNotesContainer;
