import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BlockHeadBetween, BlockHeadContent, BlockTitle } from '@/components/Block';
import { Link } from '@/components/Elements';
import { IconBack } from '@/components/Icons';
import { ContentLayout } from '@/components/Layouts';
import { useDetails } from '@/modules/shared/hooks';
import { path } from '@/utils';

import { ContactForm } from '../components/ContactForm';
import { Contact } from '../types';

import { CONTACT_ROUTES } from '.';

export const EditContact = () => {
	const navigate = useNavigate();
	const contactQuery = useDetails<Contact>('/contacts');

	const onSuccess = (): void => {
		navigate(path(CONTACT_ROUTES.show, ':id', contactQuery.id));
	};

	return (
		<ContentLayout title="Créer un contact">
			<BlockHeadBetween size="sm">
				<BlockHeadContent>
					<BlockTitle>
						Contacts /{' '}
						<strong className="text-primary small">{contactQuery.data?.name}</strong>
					</BlockTitle>
				</BlockHeadContent>
				<BlockHeadContent>
					<Link
						to={path(CONTACT_ROUTES.show, ':id', contactQuery.id)}
						className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
					>
						<IconBack />
						<span className="pl-1">Retourner</span>
					</Link>
				</BlockHeadContent>
			</BlockHeadBetween>
			<div className="row">
				<div className="col-lg-6">
					<ContactForm contact={contactQuery.data} onSuccess={onSuccess} isEditing />
				</div>
			</div>
		</ContentLayout>
	);
};
