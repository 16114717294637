import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';
import { BaseEntity } from '@/types';

export type UpdateDTO<TypeDTO> = TypeDTO & {
	id: string;
	path: string;
};

export function edit<Type, TypeDTO>({ path, id, ...data }: UpdateDTO<TypeDTO>): Promise<Type> {
	return axios.patch(`${path}/${id}`, data);
}

type UseUpdateOptions = {
	key: string;
	successMessage: string;
	config?: MutationConfig<typeof edit>;
};

export function useEdit<Type extends BaseEntity, TypeDTO>({
	key,
	successMessage,
	config,
}: UseUpdateOptions) {
	const { addNotification } = useNotificationStore();

	return useMutation<Type, AxiosError<unknown, any>, UpdateDTO<TypeDTO>, any>({
		onMutate: async (editingItem: any) => {
			await queryClient.cancelQueries([key, editingItem?.contactId]);

			const previousItem = queryClient.getQueryData<Type>([key, editingItem?.contactId]);

			queryClient.setQueryData([key, editingItem?.contactId], {
				...previousItem,
				...editingItem.data,
				id: editingItem.contactId,
			});

			return { previousItem };
		},
		onError: (_, __, context: any) => {
			if (context?.previousItem) {
				queryClient.setQueryData([key, context.previousItem.id], context.previousItem);
			}
		},
		onSuccess: (data) => {
			queryClient.refetchQueries([key, data.id]);
			addNotification({
				type: 'success',
				title: successMessage,
			});
		},
		...config,
		mutationFn: edit,
	});
}
