import { useNotificationStore } from '@/stores/notifications';

import { Notification } from './Notification';

export const Notifications = () => {
	const { notifications, dismissNotification } = useNotificationStore();

	return (
		<div id="toast-container" className="toast-top-right">
			{notifications.map((notification) => (
				<Notification
					key={notification.id}
					notification={notification}
					onDismiss={dismissNotification}
				/>
			))}
		</div>
	);
};
