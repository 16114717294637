import { AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

export type CreateDTO<TypeDTO> = {
	data: TypeDTO;
	path: string;
};

function create<Type, TypeDTO>({ path, data }: CreateDTO<TypeDTO>): Promise<Type> {
	return axios.post(path, data);
}

type UseCreateOptions = {
	key: string;
	successMessage: string;
	config?: MutationConfig<typeof create>;
};

export function useCreate<Type, TypeDTO>({ config, key, successMessage }: UseCreateOptions) {
	const { addNotification } = useNotificationStore();
	return useMutation<Type, AxiosError<unknown, any>, CreateDTO<TypeDTO>, unknown>({
		onMutate: async (newItem) => {
			await queryClient.cancelQueries(key);

			const previousItems = queryClient.getQueryData<Type[]>(key);

			queryClient.setQueryData(key, [...(previousItems || []), newItem.data]);

			return { previousItems };
		},
		onError: (_, __, context: any) => {
			if (context?.previousItems) {
				queryClient.setQueryData(key, context.previousItems);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries(key);
			addNotification({
				type: 'success',
				title: successMessage,
			});
		},
		...config,
		mutationFn: create,
	});
}
