import { Ticket } from '../types';

import TicketTableRow from './TicketTableRow';

interface TicketsTableProps {
	tickets: Ticket[];
}

function sort(a: Ticket, b: Ticket): number {
	const equals = a.status == b.status;
	const rhs = a.status === 'opened' ? -1 : 1;
	return equals ? 0 : rhs;
}

function sortedTickets(tickets: Ticket[]): Ticket[] {
	return tickets.sort(sort);
}

function TicketsTable({ tickets }: TicketsTableProps) {
	return (
		<table className="table table-tickets">
			<thead className="tb-ticket-head">
				<tr className="tb-ticket-title">
					<th className="tb-ticket-id">
						<span>Ticket</span>
					</th>
					<th className="tb-ticket-desc">
						<span>Titre</span>
					</th>
					<th className="tb-ticket-desc">
						<span>Type</span>
					</th>
					<th className="tb-ticket-date tb-col-md">
						<span>{`Date d'ajout`}</span>
					</th>
					<th className="tb-ticket-seen tb-col-md">
						<span>Attribué à</span>
					</th>
					<th className="tb-ticket-status">
						<span>Statut</span>
					</th>
					<th className="tb-ticket-action"> &nbsp; </th>
				</tr>
			</thead>
			<tbody className="tb-ticket-body">
				{sortedTickets(tickets).map((ticket, index) => (
					<TicketTableRow key={`t${index}`} ticket={ticket} />
				))}
			</tbody>
		</table>
	);
}

export default TicketsTable;
