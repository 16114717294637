import clsx from 'clsx';
import { Link, LinkProps } from 'react-router-dom';

type ButtonLinkProps = Omit<LinkProps, 'to'> & {
	size?: 'sm' | 'md' | 'lg';
	variant?: 'danger' | 'success' | 'primary' | 'info' | 'warning' | 'dark';
	className?: string;
	to?: string;
};

export const ButtonLink = ({
	variant,
	className,
	size,
	to = '#',
	children,
	...props
}: ButtonLinkProps) => {
	return (
		<Link
			to={to}
			className={clsx('link', size && `btn-${size}`, variant && `link-${variant}`, className)}
			{...props}
		>
			{children}
		</Link>
	);
};
