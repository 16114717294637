import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
import {
	AuthUser,
	getProfile,
	signin,
	SignInCredentialsTDO,
	signup,
	SignUpCredentialsDTO,
	UserResponse,
} from '@/modules/auth';
import storage from '@/utils/storage';

async function handleUserResponse(data: UserResponse) {
	const { jwt, user } = data;
	storage.setToken(jwt);
	return user;
}

async function loadUser() {
	if (storage.getToken()) {
		try {
			const data = await getProfile();
			return data;
		} catch (error) {
			storage.clearToken();
			window.location.assign('/auth/signin');
		}
	}
	return null;
}

async function signinFn(data: SignInCredentialsTDO) {
	const response = await signin(data);
	const user = await handleUserResponse(response);

	return user;
}

async function signupFn(data: SignUpCredentialsDTO) {
	const response = await signup(data);
	const user = await handleUserResponse(response);

	return user;
}

async function signoutFn() {
	storage.clearToken();
	window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
	loadUser,
	loginFn: signinFn,
	registerFn: signupFn,
	logoutFn: signoutFn,
	LoaderComponent() {
		return <Spinner />;
	},
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
	AuthUser | null,
	unknown,
	SignInCredentialsTDO,
	SignUpCredentialsDTO
>(authConfig);
