import { Link } from '@/components/Elements';
import { path, userAvatar } from '@/utils';

import { CONTACT_ROUTES } from '../routes';
import { Contact as iContact } from '../types';

interface ContactProps {
	contact: iContact;
}

const DETAILS = (id: string) => path(CONTACT_ROUTES.show, ':id', id);

function Contact({ contact }: ContactProps) {
	return (
		<div className="nk-tb-item">
			<div className="nk-tb-col nk-tb-col-check">
				<div className="custom-control custom-control-sm custom-checkbox notext">
					<label className="custom-control-label" htmlFor={`cid-${contact.id}`}>
						<input
							type="checkbox"
							className="custom-control-input"
							id={`cid${contact.id}`}
						/>
					</label>
				</div>
			</div>
			<div className="nk-tb-col">
				<Link className="user-card cursor-pointer" to={DETAILS(contact.id)}>
					<div className="user-avatar sm mb-1 bg-primary">
						<span>{userAvatar(contact.name || contact.email)}</span>
					</div>
					<div className="user-info">
						<span className="tb-lead">{contact.name}</span>
						<span>{contact.email}</span>
					</div>
				</Link>
			</div>
			<div className="nk-tb-col tb-col-lg">
				<span>{contact.phone}</span>
			</div>
			<div className="nk-tb-col tb-col-lg">
				<span>{contact.createdAt}</span>
			</div>
			<div className="nk-tb-col tb-col-md">
				{/* <span
					className={`tb-status text-${
						contact.status === 'active' ? 'success' : 'warning'
					}`}
				>
					{contact.status}
				</span> */}
				<span className="tb-status text-success">Active</span>
			</div>
			<div className="nk-tb-col nk-tb-col-tools">
				<ul className="nk-tb-actions gx-1">
					<li className="nk-tb-action-hidden">
						<Link
							to={DETAILS(contact.id)}
							className="btn btn-trigger btn-icon"
							data-toggle="tooltip"
							data-placement="top"
							title="Suspend"
						>
							<em className="icon ni ni-eye-fill"></em>
						</Link>
					</li>
					<li className="nk-tb-action-hidden">
						<Link
							to="#"
							className="btn btn-trigger btn-icon"
							data-toggle="tooltip"
							data-placement="top"
							title="Send Email"
						>
							<em className="icon ni ni-mail-fill"></em>
						</Link>
					</li>
					<li>
						<div className="drodown">
							<Link
								to="#"
								className="dropdown-toggle btn btn-icon btn-trigger"
								data-toggle="dropdown"
							>
								<em className="icon ni ni-more-h"></em>
							</Link>
							<div className="dropdown-menu dropdown-menu-right">
								<ul className="link-list-opt no-bdr">
									<li>
										<Link to="#">
											<em className="icon ni ni-eye"></em>
											<span>View Details</span>
										</Link>
									</li>
									<li>
										<Link
											to="#"
											data-toggle="modal"
											data-target="#editCustomer"
										>
											<em className="icon ni ni-edit"></em>
											<span>Edit</span>
										</Link>
									</li>
									<li>
										<Link to="#">
											<em className="icon ni ni-activity-round"></em>
											<span>Activities</span>
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default Contact;
