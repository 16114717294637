import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorFallback, Spinner } from '@/components/Elements';
import { Notifications } from '@/components/Notifications';
import { AuthProvider } from '@/lib/auth';
import { queryClient } from '@/lib/react-query';

type AppProviderProps = {
	children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
	return (
		<React.Suspense fallback={<Spinner />}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<HelmetProvider>
					<QueryClientProvider client={queryClient}>
						<Notifications />
						<AuthProvider>
							<Router>{children}</Router>
						</AuthProvider>
					</QueryClientProvider>
				</HelmetProvider>
			</ErrorBoundary>
		</React.Suspense>
	);
};
