import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route, Routes } from 'react-router-dom';

import { ErrorFallback, Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { AppLayout } from '@/components/Layouts';

import { Sidebar } from '../components/Sidebar';

import { Dashboard } from './Dashboard';
import { Estimates } from './Estimates';
import { Invoices } from './Invoices';
import { Settings } from './Settings';
import { Statistics } from './Statistics';

const prefix = '/app/invoicing';

export const INVOICING_ROUTES = {
	dashboard: prefix,
	invoices: `${prefix}/invoices`,
	invoice_new: `${prefix}/invoices/new`,
	estimates: `${prefix}/estimates`,
	estimate_new: `${prefix}/estimates/new`,
	estimate_show: `${prefix}/estimates/:id`,
	settings: `${prefix}/settings`,
	statistics: `${prefix}/statistics`,
};

const InvoiceApp = () => {
	return (
		<Suspense fallback={<Spinner />}>
			<Head title="Facturation" />
			<AppLayout>
				<Sidebar />
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Outlet />
				</ErrorBoundary>
			</AppLayout>
		</Suspense>
	);
};

export const InvoicingRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<InvoiceApp />}>
				<Route path="" element={<Dashboard />} />
				<Route path="estimates" element={<Estimates />} />
				<Route path="invoices" element={<Invoices />} />
				<Route path="settings" element={<Settings />} />
				<Route path="statistics" element={<Statistics />} />
			</Route>
		</Routes>
	);
};
