import React from 'react';

import { Head } from '../Head';

type ContentLayoutProps = {
	title?: string;
	children: React.ReactNode;
};

export const ContentLayout = ({ title, children }: ContentLayoutProps) => {
	return (
		<>
			{title && <Head title={title} />}
			<div className="nk-content-inner">
				<div className="nk-content-body">{children}</div>
			</div>
		</>
	);
};
