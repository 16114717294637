import React from 'react';
import { FallbackProps } from 'react-error-boundary';

import { Button } from '../Button';

export type ErrorFallbackProps = FallbackProps & {
	title?: string;
};

export const ErrorFallback = ({
	error,
	title = 'Ooops, something went wrong :(',
}: ErrorFallbackProps) => {
	return (
		<div className="text-center mt-5 pt-5" role="alert">
			<h2 className="fw-bold text-danger">{title}</h2>
			<h5 className="fw-medium">{error.message}</h5>
			<Button
				className="mt-4"
				onClick={() => window.location.assign(window.location.pathname)}
			>
				Refresh
			</Button>
		</div>
	);
};
