import React from 'react';
import { Nav, Spinner } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { Outlet, useLocation } from 'react-router-dom';

import { BackButton, Link } from '@/components/Elements';
import { useDetails } from '@/modules/shared/hooks';
import { path } from '@/utils';

import { Contact as ContactType } from '../types';

import { CONTACT_ROUTES } from '.';

const Loading = () => (
	<ContentLoader
		speed={2}
		width={400}
		height={16}
		viewBox="0 0 400 16"
		backgroundColor="#f3f3f3"
		foregroundColor="#ecebeb"
	>
		<rect width="410" height="15" />
	</ContentLoader>
);

export const Contact = () => {
	const { pathname } = useLocation();
	const { id: contactId, isLoading, data: contact } = useDetails<ContactType>('/contacts');

	if (isLoading) {
		return (
			<div className="text-center mt-5 pt-5">
				<Spinner animation="border" />
			</div>
		);
	}

	const editPath = path(CONTACT_ROUTES.edit, ':id', contactId);

	if (!contact) return null;

	return (
		<React.Fragment>
			<div className="nk-block-head nk-block-head-sm">
				<div className="nk-block-between g-3">
					<div className="nk-block-head-content">
						<div className="mb-2">
							<BackButton to={''} />
						</div>
						<h3 className="nk-block-title page-title">
							Contacts /{' '}
							{isLoading ? (
								<Loading />
							) : (
								<strong className="text-primary small">
									{contact?.name || contact?.email}
								</strong>
							)}
						</h3>
						<div className="nk-block-des text-soft">
							<ul className="list-inline">
								<li>
									{"Date d'ajout: "}
									<span className="text-base">{contact?.createdAt}</span>
								</li>
							</ul>
						</div>
					</div>
					<div className="nk-block-head-content">
						<Link
							to={editPath}
							className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
						>
							<em className="icon ni ni-edit"></em>
							<span>Modifier</span>
						</Link>
						<Link
							to={editPath}
							className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
						>
							<em className="icon ni ni-edit"></em>
						</Link>
					</div>
				</div>
			</div>
			<div className="nk-block">
				<div className="card">
					<div className="card-aside-wrap">
						<div className="card-content">
							<Nav
								className="nav-tabs-mb-icon nav-tabs-card"
								variant="tabs"
								defaultActiveKey="activity"
							>
								<Nav.Item
									className={
										pathname === path(CONTACT_ROUTES.show, ':id', contactId)
											? 'active'
											: ''
									}
								>
									<Link className="nav-link" to="">
										<em className="icon ni ni-history"></em>
										<span>Activités</span>
									</Link>
								</Nav.Item>
								<Nav.Item className={pathname.endsWith('notes') ? 'active' : ''}>
									<Link
										className="nav-link"
										to={path(CONTACT_ROUTES.notes, ':id', contactId)}
									>
										<em className="icon ni ni-notes"></em>
										<span>Notes</span>
									</Link>
								</Nav.Item>
								{/* <Nav.Item className={pathname.endsWith('tasks') ? 'active' : ''}>
                  <Link className='nav-link' to={path(CONTACT_ROUTES.messages, ':id', id)}>
                    <em className="icon ni ni-clipboad-check"></em><span>Tâches</span>
                  </Link>
                </Nav.Item> */}
								<Nav.Item className={pathname.endsWith('messages') ? 'active' : ''}>
									<Link
										className="nav-link"
										to={path(CONTACT_ROUTES.messages, ':id', contactId)}
									>
										<em className="icon ni ni-send"></em>
										<span>Messages</span>
									</Link>
								</Nav.Item>
								<Nav.Item
									className={pathname.endsWith('documents') ? 'active' : ''}
								>
									<Link
										className="nav-link"
										to={path(CONTACT_ROUTES.documents, ':id', contactId)}
									>
										<em className="icon ni ni-file-text"></em>
										<span>Documents</span>
									</Link>
								</Nav.Item>
								<Nav.Item className="nav-item-trigger d-xxl-none">
									<Link className="toggle btn btn-icon btn-trigger" to="">
										<em className="icon ni ni-user-list-fill"></em>
									</Link>
								</Nav.Item>
							</Nav>
							<div className="card-inner">
								<Outlet />
							</div>
						</div>
						<div
							className="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg"
							data-content="userAside"
							data-toggle-screen="lg"
							data-toggle-overlay="true"
							data-toggle-body="true"
						>
							<div className="card-inner-group" data-simplebar>
								<div className="card-inner">
									<div className="user-card user-card-s2">
										<div className="user-avatar lg bg-primary">
											<span>{(contact?.name || contact?.email)?.at(0)}</span>
										</div>
										<div className="user-info">
											{/* <div className="badge badge-outline-light badge-pill ucap">Investor</div> */}
											<h5>{contact?.name || '-'}</h5>
											<span className="text-body">
												{contact?.email || '-'}
											</span>
										</div>
									</div>
								</div>
								<div className="card-inner card-inner-sm">
									<ul className="btn-toolbar justify-center gx-1">
										<li>
											<Link
												to={CONTACT_ROUTES.messages}
												className="btn btn-trigger btn-icon"
											>
												<em className="icon ni ni-mail"></em>
											</Link>
										</li>
										<li>
											<Link to="#" className="btn btn-trigger btn-icon">
												<em className="icon ni ni-bookmark"></em>
											</Link>
										</li>
										<li>
											<Link
												to="#"
												className="btn btn-trigger btn-icon text-danger"
											>
												<em className="icon ni ni-na"></em>
											</Link>
										</li>
									</ul>
								</div>
								{/* <div className="card-inner">
                  <div className="row text-center">
                    <div className="col-4">
                      <div className="profile-stats">
                        <span className="amount">23</span>
                        <span className="sub-text">E-mails</span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="profile-stats">
                        <span className="amount">20</span>
                        <span className="sub-text">Complete</span>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="profile-stats">
                        <span className="amount">3</span>
                        <span className="sub-text">Progress</span>
                      </div>
                    </div>
                  </div>
                </div> */}
								<div className="card-inner">
									<h6 className="overline-title-alt mb-2">
										Informations utilisateur
									</h6>
									<div className="row g-3">
										<div className="col-6">
											<span className="sub-text">ID:</span>
											<span>UD00{contactId}</span>
										</div>
										<div className="col-6">
											<span className="sub-text">Statut:</span>
											<span
												className={`lead-text text-${
													contact?.status === 'active'
														? 'success'
														: 'danger'
												}`}
											>
												{contact?.status?.toLocaleUpperCase()}
											</span>
										</div>
										<div className="col-6">
											<span className="sub-text">Nom:</span>
											<span>{contact?.name}</span>
										</div>
										<div className="col-6">
											<span className="sub-text">SMS (Téléphone):</span>
											<span>{contact?.phone}</span>
										</div>
										<div className="border-bottom w-100 mb-3"></div>
										<div className="col-6">
											<span className="sub-text">Dernière modif:</span>
											<span>Wed 09 2022, 01:02 PM</span>
										</div>
										<div className="col-6">
											<span className="sub-text">{"Date d'ajout:"}</span>
											<span>{contact?.createdAt}</span>
										</div>
									</div>
								</div>
								{/* <div className="card-inner">
									<h6 className="overline-title-alt mb-3">Listes</h6>
									<ul className="g-1">
										<li className="btn-group">
										<a className="btn btn-xs btn-light btn-dim" href="#">investor</a>
										<a className="btn btn-xs btn-icon btn-light btn-dim" href="#"><em className="icon ni ni-cross"></em></a>
										</li>
										<li className="btn-group">
										<a className="btn btn-xs btn-light btn-dim" href="#">support</a>
										<a className="btn btn-xs btn-icon btn-light btn-dim" href="#"><em className="icon ni ni-cross"></em></a>
										</li>
										<li className="btn-group">
										<a className="btn btn-xs btn-light btn-dim" href="#">another tag</a>
										<a className="btn btn-xs btn-icon btn-light btn-dim" href="#"><em className="icon ni ni-cross"></em></a>
										</li>
									</ul>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
