import React from 'react';
import { Spinner } from 'react-bootstrap';
import nl2br from 'react-nl2br';

import { BackButton, Badge } from '@/components/Elements';
import { useDetails } from '@/modules/shared/hooks';
import { formatDate } from '@/utils/format';
import { userAvatar } from '@/utils/userAvatar';

import { Ticket as TicketType } from '../types';

export const Ticket = () => {
	const { isLoading, data: ticket } = useDetails<TicketType>('/tickets');

	if (isLoading) {
		return (
			<div className="text-center mt-5 pt-5">
				<Spinner animation="border" />
			</div>
		);
	}

	if (!ticket) return null;

	return (
		<React.Fragment>
			<div className="nk-block-head">
				<div className="nk-block-between g-3">
					<div className="nk-block-head-content">
						<h3 className="nk-block-title page-title">{ticket.title}</h3>
					</div>
					<div className="nk-block-head-content">
						<BackButton />
					</div>
				</div>
			</div>
			<div className="nk-block">
				<div className="ticket-info">
					<ul className="ticket-meta">
						<li className="ticket-id">
							<span>Ticket ID:</span> <strong>{ticket.id}</strong>
						</li>
						<li className="ticket-date">
							<span>Type:</span> <strong>{ticket.type}</strong>
						</li>
						<li className="ticket-date">
							<span>Created by:</span> <strong>{ticket.createdBy?.name}</strong>
						</li>
					</ul>
					<div className="ticket-status">
						<Badge
							type={ticket.status == 'opened' ? 'primary' : 'danger'}
							text={ticket.status || ''}
						/>
					</div>
				</div>
			</div>
			<div className="nk-block nk-block-lg">
				<div className="card card-bordered">
					<div className="card-inner">
						<div className="ticket-msgs">
							<div className="ticket-msg-item">
								<div className="ticket-msg-from">
									<div className="ticket-msg-user user-card">
										<div className="user-avatar bg-primary">
											<span>{userAvatar(ticket.customer?.name)}</span>
										</div>
										<div className="user-info">
											<span className="lead-text">
												{ticket.customer?.name}
											</span>
											{/* <span className="text-soft">Customer</span> */}
										</div>
									</div>
									<div className="ticket-msg-date">
										<span className="sub-text">
											{formatDate(ticket.createdAt)}
										</span>
									</div>
								</div>
								<div className="ticket-msg-comment">
									{nl2br(ticket.description)}
								</div>
							</div>
							<div className="ticket-msg-reply">
								<h5 className="title">Répondre</h5>
								<form action="#" className="form-reply">
									<div className="form-group">
										<div className="form-editor-custom">
											<textarea
												className="form-control"
												placeholder="Écrivez votre message..."
											></textarea>
											<div className="form-editor-action">
												<ul className="form-editor-btn-group">
													<li className="form-btn-more">
														<a
															href="#"
															data-toggle="tooltip"
															title=""
															className="btn btn-icon"
															data-original-title="Add File"
														>
															<em className="icon ni ni-clip-h"></em>
														</a>
													</li>
													<li className="form-btn-more">
														<a
															href="#"
															data-toggle="tooltip"
															title=""
															className="btn btn-icon"
															data-original-title="Add Image"
														>
															<em className="icon ni ni-img"></em>
														</a>
													</li>
													<li className="form-btn-more">
														<a
															href="#"
															data-toggle="tooltip"
															title=""
															className="btn btn-icon"
															data-original-title="Add Link"
														>
															<em className="icon ni ni-link"></em>
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div className="form-action">
										<ul className="form-btn-group">
											<li className="form-btn-primary">
												<a href="#" className="btn btn-primary">
													Envoyer
												</a>
											</li>
											<li className="form-btn-secondary">
												<a
													href="#"
													className="btn btn-dim btn-outline-light"
												>
													Marquer comme fermé
												</a>
											</li>
										</ul>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
