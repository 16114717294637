import clsx from 'clsx';
import React from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
	label?: string;
	containerClassName?: string;
	htmlFor?: string;
	children?: React.ReactNode;
	error?: FieldError | undefined;
	group?: boolean;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'children'>;

export const FieldWrapper = (props: FieldWrapperProps) => {
	const { error, label, containerClassName, group = true, htmlFor, children } = props;

	return (
		<div className={clsx(group && 'form-group', containerClassName)}>
			{label && (
				<div className="form-label-group">
					<label className="form-label" htmlFor={htmlFor}>
						{label}
					</label>
				</div>
			)}
			<div className="form-control-wrap">{children}</div>
			{error?.message && <span className="invalid text-danger">{error.message}</span>}
		</div>
	);
};
