import { Link } from '@/components/Elements';
import { CONTACT_ROUTES } from '@/modules/contacts';
import { formatDate, path } from '@/utils';

import { INVOICING_ROUTES } from '../routes';
import { Estimate as iEstimate } from '../types';

interface EstimateProps {
	estimate: iEstimate;
}

const DETAILS = (id: string) => path(INVOICING_ROUTES.estimate_show, ':id', id);

function Estimate({ estimate }: EstimateProps) {
	return (
		<tr className="tb-odr-item">
			<td className="tb-odr-info">
				<span className="tb-odr-id">
					<Link to={DETAILS(estimate.id)}>#{estimate.uid}</Link>
				</span>
				<span className="tb-odr-date">{formatDate(estimate.createdAt)}</span>
			</td>
			<td className="tb-odr-customer">
				<span className="tb-odr-customer-name">
					<Link to={path(CONTACT_ROUTES.show, ':id', estimate.client.id)}>
						{estimate.client.name}
					</Link>
				</span>
			</td>
			<td className="tb-odr-amount">
				<span className="tb-odr-total">
					<span className="amount">
						{estimate.balanceDue} {estimate.currency}
					</span>
				</span>
				<span className="tb-odr-status">
					<span className="badge badge-dot badge-warning">{estimate.status}</span>
				</span>
			</td>
			<td className="tb-odr-action">
				<div className="tb-odr-btns d-none d-sm-inline">
					<Link
						to={DETAILS(estimate.id)}
						className="btn btn-icon btn-white btn-dim btn-sm btn-primary"
					>
						Voir
					</Link>
				</div>
				<Link to={DETAILS(estimate.id)} className="btn btn-pd-auto d-sm-none">
					<em className="icon ni ni-chevron-right"></em>
				</Link>
				<Link to={DETAILS(estimate.id)} className="btn btn-pd-auto d-sm-none">
					<em className="icon ni ni-chevron-right"></em>
				</Link>
			</td>
		</tr>
	);
}

export default Estimate;
