import { Link } from '@/components/Elements';

import { Contact as iContact } from '../types';

import Contact from './Contact';

interface ContactsListProps {
	contacts: iContact[];
}

function ContactsList({ contacts }: ContactsListProps) {
	return (
		<div className="card-inner p-0">
			<div className="nk-tb-list nk-tb-ulist is-compact">
				<div className="nk-tb-item nk-tb-head">
					<div className="nk-tb-col nk-tb-col-check">
						<div className="custom-control custom-control-sm custom-checkbox notext">
							{/* <input type="checkbox" className="custom-control-input" id="uid" /> */}
							<label className="custom-control-label" htmlFor="uid">
								<input type="checkbox" className="custom-control-input" id="uid" />
							</label>
						</div>
					</div>
					<div className="nk-tb-col">
						<span className="sub-text text-body font-weight-bold">Client</span>
					</div>
					<div className="nk-tb-col tb-col-lg">
						<span className="sub-text text-body font-weight-bold">Téléphone</span>
					</div>
					<div className="nk-tb-col tb-col-lg">
						<span className="sub-text text-body font-weight-bold">
							{"Date d'ajout"}
						</span>
					</div>
					<div className="nk-tb-col tb-col-md">
						<span className="sub-text text-body font-weight-bold">Statut</span>
					</div>
					<div className="nk-tb-col nk-tb-col-tools text-right">
						<div className="dropdown">
							<Link
								to="#"
								className="btn btn-xs btn-outline-light btn-icon dropdown-toggle"
								data-toggle="dropdown"
								data-offset="0,5"
							>
								<em className="icon ni ni-plus"></em>
							</Link>
							<div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
								<ul className="link-tidy sm no-bdr">
									<li>
										<div className="custom-control custom-control-sm custom-checkbox">
											<input
												type="checkbox"
												className="custom-control-input"
												id="ph"
											/>
											<label className="custom-control-label" htmlFor="ph">
												Phone
											</label>
										</div>
									</li>
									<li>
										<div className="custom-control custom-control-sm custom-checkbox">
											<input
												type="checkbox"
												className="custom-control-input"
												id="bl"
											/>
											<label className="custom-control-label" htmlFor="bl">
												Balance
											</label>
										</div>
									</li>
									<li>
										<div className="custom-control custom-control-sm custom-checkbox">
											<input
												type="checkbox"
												className="custom-control-input"
												id="st"
											/>
											<label className="custom-control-label" htmlFor="st">
												Status
											</label>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				{contacts?.map((c, i) => (
					<Contact key={i} contact={c} />
				))}
			</div>
		</div>
	);
}

export default ContactsList;
