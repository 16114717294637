import '@/assets/scss/app.scss';
import '@/assets/scss/theme.scss';

import { AuthRoutes } from '@/modules/auth';

export const authRoutes = [
	{
		path: '/auth/*',
		element: <AuthRoutes />,
	},
];
