import React from 'react';
import * as z from 'zod';

import { Card, CardTitle } from '@/components/Card';
import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { useCreateItem, useUpdateItem } from '@/modules/shared/hooks';

import { Contact, CreateContactDTO } from '../types';

import { CreateContactIcon } from './CreateContactIcon';

const schema = z.object({
	name: z.string().min(2, 'Ce champs obligatoire (2 caracètres min)'),
	email: z.string().email('Entrez une adresse e-mail valide.'),
	phone: z.string().min(6, 'Ce champs obligatoire'),
});

type ContactFormProps = {
	contact?: Contact;
	onSuccess: (contact: Contact) => void;
	isEditing?: boolean;
};

export const ContactForm = ({ contact, onSuccess, isEditing = false }: ContactFormProps) => {
	const createContactMutation = useCreateItem<Contact, CreateContactDTO>({
		key: 'contacts',
		successMessage: 'Contact crée.',
	});
	const editContactMutation = useUpdateItem<Contact, CreateContactDTO>({
		key: 'contact',
		successMessage: 'Contact modifié.',
	});

	const handleSubmit = async (values: CreateContactDTO) => {
		if (isEditing && contact?.id) {
			editContactMutation
				.mutateAsync({ path: '/contacts', id: contact?.id, ...values })
				.then((res) => onSuccess(res));
		} else {
			const contact = await createContactMutation.mutateAsync({
				data: values,
				path: '/contacts',
			});
			onSuccess(contact);
		}
	};

	return (
		<Card>
			<CardTitle>Formulaire</CardTitle>
			<p>
				<em className="text-danger">*</em> Champs obligatoires.
			</p>
			<Form<CreateContactDTO, typeof schema>
				onSubmit={handleSubmit}
				schema={schema}
				className="gy-3 form-settings"
			>
				{({ register, formState }) => (
					<>
						<InputField
							type="text"
							label="Nom du contact"
							placeholder="Entrez le nom de contact"
							error={formState.errors['name']}
							registration={register('name')}
							defaultValue={contact?.name}
							group={false}
						/>
						<InputField
							type="email"
							label="Email"
							placeholder="Entrez l'adresse email"
							error={formState.errors['email']}
							registration={register('email')}
							defaultValue={contact?.email}
							group={false}
						/>
						<InputField
							type="tel"
							label="Numéro de téléphone"
							placeholder="Entrez le numéro de téléphone"
							error={formState.errors['phone']}
							registration={register('phone')}
							defaultValue={contact?.phone}
							group={false}
						/>
						<Button
							disabled={createContactMutation.isLoading}
							size="lg"
							variant="primary"
							type="submit"
							className="btn btn-block"
						>
							{createContactMutation.isLoading ? <Spinner /> : <CreateContactIcon />}
							<span>
								{isEditing ? 'Enregistrer les modifications' : 'Créer le contact'}
							</span>
						</Button>
					</>
				)}
			</Form>
		</Card>
	);
};
