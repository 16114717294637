import clsx from 'clsx';
import React from 'react';

import { Link, LinkProps } from '../Elements';

export type CardLinkProps = LinkProps & {
	bordered?: boolean;
	children: React.ReactNode;
};

export const CardLink = ({ bordered, children, className, ...props }: CardLinkProps) => {
	return (
		<Link className={clsx('card', bordered && 'card-bordered', className)} {...props}>
			<div className="card-inner">{children}</div>
		</Link>
	);
};
