import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { ContactNote } from '../types';

export const addNote = ({ note }: { note: ContactNote }): Promise<ContactNote> => {
	note.contact = `/contacts/${note.contact}`;
	return axios.post('contact/notes', note);
};

type UseAddNoteOptions = {
	config?: MutationConfig<typeof addNote>;
};

export const useAddNote = ({ config }: UseAddNoteOptions = {}) => {
	const { addNotification } = useNotificationStore();

	return useMutation({
		onMutate: async (newNote) => {
			await queryClient.cancelQueries('notes');

			const previousNotes = queryClient.getQueryData<ContactNote[]>('notes');

			queryClient.setQueryData('notes', [...(previousNotes || []), newNote.note]);

			return { previousNotes };
		},
		onError: (_, __, context: any) => {
			if (context?.previousNotes) {
				queryClient.setQueryData('notes', context.previousNotes);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries('notes');
			addNotification({
				type: 'success',
				title: 'Note created',
			});
		},
		...config,
		mutationFn: addNote,
	});
};
