import { ReactNode } from 'react';

import { Footer } from '@/components/Layouts';
import { APP_NAME } from '@/config';

type AuthLayoutProps = {
	title: string;
	subtitle?: string;
	children: ReactNode;
};

export const AuthLayout = ({ title, subtitle, children }: AuthLayoutProps) => {
	return (
		<div className="nk-main">
			<div className="nk-wrap nk-wrap-nosidebar">
				<div className="nk-content ">
					<div className="nk-block nk-block-middle nk-auth-body  wide-xs">
						<div className="brand-logo pb-4 text-center">
							<h1 className="h2 fw-bold">{APP_NAME}</h1>
						</div>
						<div className="card card-bordered">
							<div className="card-inner card-inner-lg">
								<div className="nk-block-head">
									<div className="nk-block-head-content">
										<h4 className="nk-block-title">{title}</h4>
										{subtitle && (
											<div className="nk-block-des mb-3">
												<p>Connectez-vous pour accèder à votre compte.</p>
											</div>
										)}
									</div>
								</div>
								{children}
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</div>
	);
};
