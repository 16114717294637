import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';
import { PartialCollectionResults } from '@/types';

type ListingOptions = {
	page: number;
	path: string;
};

export function getListing<Type>({
	path,
	page,
}: ListingOptions): Promise<PartialCollectionResults<Type>> {
	return axios.get(`${path}?page=${page}`);
}

type QueryFnType = typeof getListing;

type UseListingOptions = {
	page: number;
	path: string;
	config?: QueryConfig<QueryFnType>;
};

export function useList<Type>({
	page,
	path,
	config,
}: UseListingOptions): UseQueryResult<PartialCollectionResults<Type>, unknown> {
	return useQuery<
		PartialCollectionResults<Type>,
		unknown,
		PartialCollectionResults<Type>,
		QueryKey
	>({
		...config,
		queryKey: [path, page],
		queryFn: () => getListing<Type>({ page, path }),
	} as UseQueryOptions<PartialCollectionResults<Type>, unknown, PartialCollectionResults<Type>, QueryKey>);
}
