import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { useNotificationStore } from '@/stores/notifications';

import { ContactNote } from '../types';

export const deleteNote = ({ noteId }: { noteId: string }): Promise<void> => {
	return axios.delete(`contact/notes/${noteId}`);
};

type UseDeleteNoteOptions = {
	config?: MutationConfig<typeof deleteNote>;
};

export const useDeleteNote = ({ config }: UseDeleteNoteOptions = {}) => {
	const { addNotification } = useNotificationStore();

	return useMutation({
		onMutate: async (deletingNote: any) => {
			await queryClient.cancelQueries(['note', deletingNote?.noteId]);

			const previousNote = queryClient.getQueryData<ContactNote>([
				'note',
				deletingNote?.noteId,
			]);

			queryClient.setQueryData(['note', deletingNote?.noteId], {
				...previousNote,
				id: deletingNote.noteId,
			});

			return { previousNote };
		},
		onError: (_, __, context: any) => {
			if (context?.previousNote) {
				queryClient.setQueryData(['note', context.previousNote.id], context.previousNote);
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries('notes');
			addNotification({
				type: 'success',
				title: 'Note supprimée',
			});
		},
		...config,
		mutationFn: deleteNote,
	});
};
