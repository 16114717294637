import React from 'react';

import {
	BlockHeadBetween,
	BlockHeadContent,
	BlockText,
	BlockTitle,
	BlockToolItem,
	BlockTools,
} from '@/components/Block';
import { Spinner, Paginate } from '@/components/Elements';
import { ContentLayout } from '@/components/Layouts';
import { useListing } from '@/modules/shared/hooks';

import { ContactsList } from '../components';
import { Contact } from '../types';

import { CONTACT_ROUTES } from '.';

export const Contacts = () => {
	const {
		data: contacts,
		isLoading,
		page,
		pageCount,
		handlePageClick,
	} = useListing<Contact>('/contacts');

	return (
		<ContentLayout title="Contacts">
			<BlockHeadBetween>
				<BlockHeadContent>
					<BlockTitle>
						<span className="nk-menu-icon">
							<em className="icon ni ni-contact-fill"></em>
						</span>
						Contacts
					</BlockTitle>
					<BlockText className="text-soft">
						{contacts?.['hydra:totalItems'] && (
							<p>Vous avez au total {contacts['hydra:totalItems']} contacts.</p>
						)}
					</BlockText>
				</BlockHeadContent>
				<BlockHeadContent>
					<BlockTools>
						<BlockToolItem to={CONTACT_ROUTES.create} className="btn btn-primary">
							<em className="icon ni ni-contact-plus"></em>
							<span>Créer un contact</span>
						</BlockToolItem>
					</BlockTools>
				</BlockHeadContent>
			</BlockHeadBetween>
			<div className="nk-block">
				<div className="card card-bordered">
					<ContactsList contacts={contacts?.['hydra:member'] || []} />
					<div className="w-100">
						<div className="text-center" style={{ height: 75, paddingTop: 25 }}>
							{isLoading && <Spinner />}
							{!isLoading && !contacts?.['hydra:totalItems'] && (
								<p className="text-body">Cette liste est vide.</p>
							)}
						</div>
					</div>
					<Paginate {...{ page, pageCount, handlePageClick }} />
				</div>
			</div>
		</ContentLayout>
	);
};
