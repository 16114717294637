import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PartialCollectionResults } from '@/types';

import { Estimate } from '../types';

export const getEstimates = (page: number): Promise<PartialCollectionResults<Estimate>> => {
	return axios.get(`/invoicing/estimates?page=${page}`);
};

type QueryFnType = typeof getEstimates;

type UseEstimatesOptions = {
	page: number;
	config?: QueryConfig<QueryFnType>;
};

export const useEstimates = ({ page, config }: UseEstimatesOptions) => {
	return useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: ['estimates', page],
		queryFn: () => getEstimates(page),
	});
};
