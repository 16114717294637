import React from 'react';

import { Footer } from './Footer';

type AppLayoutProps = {
	children: React.ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps) => {
	return (
		<>
			<div className="nk-content mt-0">
				<div className="container-fluid">{children}</div>
			</div>
			<Footer />
		</>
	);
};
