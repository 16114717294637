import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

import { axios } from '@/lib/axios';
import { QueryConfig } from '@/lib/react-query';

function getItem<Type>({ id, path }: { id: string; path: string }): Promise<Type> {
	return axios.get(`${path}/${id}`);
}

type QueryFnType = typeof getItem;

type UseItemOptions = {
	path: string;
	id: string;
	config?: QueryConfig<QueryFnType>;
};

export function useItem<Type>({ path, id, config }: UseItemOptions): UseQueryResult<Type, unknown> {
	return useQuery<Type, unknown, Type, QueryKey>({
		...config,
		queryKey: [path, id],
		queryFn: () => getItem<Type>({ path, id }),
	} as UseQueryOptions<Type, unknown, Type, QueryKey>);
}
