import React from 'react';
import nl2br from 'react-nl2br';

import { ButtonLink } from '@/components/Elements';

import { ContactNote as ContactNoteType } from '../types';

interface ContactNoteProps {
	note: ContactNoteType;
	onDelete: (id: string) => void;
}

function ContactNote({ note, onDelete }: ContactNoteProps) {
	const handleDelete = () => {
		if (onDelete && note.id) {
			onDelete(note.id);
		}
	};

	return (
		<div className="bq-note-item">
			<div className="bq-note-text">
				<p>{nl2br(note.text)}</p>
			</div>
			<div className="bq-note-meta px-1">
				<div className="d-flex justify-between">
					<div>
						<span className="bq-note-added">
							{"Date d'ajout:"} <span className="date">{note.createdAt}</span>
						</span>
						<span className="bq-note-sep sep">|</span>
						<span className="bq-note-by">
							Par <span>{note.createdBy?.name || 'Admin'}</span>
						</span>
					</div>
					<ButtonLink onClick={handleDelete} className="link link-sm link-danger">
						<small>
							<em className="icon ni ni-trash"></em> Supprimer
						</small>
					</ButtonLink>
				</div>
			</div>
		</div>
	);
}

export default ContactNote;
