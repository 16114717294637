import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BlockHeadBetween, BlockHeadContent, BlockTitle } from '@/components/Block';
import { Link } from '@/components/Elements';
import { IconBack } from '@/components/Icons';
import { ContentLayout } from '@/components/Layouts';
import { path } from '@/utils';

import { TicketForm } from '../components/TicketForm';
import { Ticket } from '../types';

import { TICKET_ROUTES } from '.';

export const CreateTicket = () => {
	const navigate = useNavigate();

	const onSuccess = (ticket: Ticket): void => {
		navigate(path(TICKET_ROUTES.show, ':id', ticket.id));
	};

	return (
		<ContentLayout title="Créer un ticket">
			<BlockHeadBetween size="sm">
				<BlockHeadContent>
					<BlockTitle>
						Tickets / <strong className="text-primary small">Créer un ticket</strong>
					</BlockTitle>
				</BlockHeadContent>
				<BlockHeadContent>
					<Link
						to={TICKET_ROUTES.list}
						className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
					>
						<IconBack />
						<span className="pl-1">Retourner</span>
					</Link>
				</BlockHeadContent>
			</BlockHeadBetween>
			<div className="row">
				<div className="col-lg-6">
					<TicketForm onSuccess={onSuccess} />
				</div>
			</div>
		</ContentLayout>
	);
};
