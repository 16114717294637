import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useContactNotes } from '../api/getContactNotes';
import ContactNotesContainer from '../components/ContactNotesContainer';

type ContactNotesParams = {
	contactId: string;
};

function ContactNotes() {
	const { contactId } = useParams<ContactNotesParams>();
	const notesQuery = useContactNotes({ contactId: contactId as string });

	return (
		<div className="nk-block">
			{notesQuery.isLoading && (
				<div className="text-center w-100">
					<Spinner animation="border" size="sm" className="mt-5" />
				</div>
			)}
			<ContactNotesContainer
				contactId={contactId}
				notes={notesQuery.data}
				onAddNote={() => notesQuery.refetch()}
				onNoteDeleted={() => notesQuery.refetch()}
			/>
		</div>
	);
}

export default ContactNotes;
