import { ReactNode } from 'react';

type ToastIconWrapperProps = {
	children: ReactNode | undefined;
};

export const ToastIconWrapper = ({ children }: ToastIconWrapperProps) => {
	if (!children) return null;

	return <span className="toastr-icon">{children}</span>;
};
