import clsx from 'clsx';
import React from 'react';

type BlockToolsProps = {
	className?: string;
	children: React.ReactNode;
};

export const BlockTools = ({ className, children }: BlockToolsProps) => {
	return <ul className={clsx('nk-block-tools g-3', className)}>{children}</ul>;
};
