import clsx from 'clsx';
import React from 'react';

export type CardProps = {
	bordered?: boolean;
	className?: string;
	children: React.ReactNode;
};

export const Card = ({ bordered, className, children }: CardProps) => {
	return (
		<div className="nk-block">
			<div className={clsx('card', bordered && 'card-bordered', className)}>
				<div className="card-inner">{children}</div>
			</div>
		</div>
	);
};
