import { Link } from '@/components/Elements';
import { Badge } from '@/components/Elements/Badge';
import { path } from '@/utils/path';

import { TICKET_ROUTES } from '../routes';
import { Ticket } from '../types';

interface TicketTableRowProps {
	ticket: Ticket;
}

const DETAILS = (id: string) => path(TICKET_ROUTES.show, ':id', id);

function TicketTableRow({ ticket }: TicketTableRowProps) {
	return (
		<tr className={`tb-ticket-item${ticket.isSeen ? '' : ' is-unread'}`}>
			<td className="tb-ticket-id">
				<Link to={DETAILS(ticket.id)}>{ticket.id}</Link>
			</td>
			<td className="tb-ticket-desc">
				<Link to={DETAILS(ticket.id)}>
					<span className="title">{ticket.title}</span>
				</Link>
			</td>
			<td className="tb-ticket">
				<span className="title">{ticket.type}</span>
			</td>
			<td className="tb-ticket-date tb-col-md">
				<span className="date">{ticket.createdAt}</span>
			</td>
			<td className="tb-ticket-seen tb-col-md">
				<div className="date-last">
					<em className="icon-avatar bg-primary-dim icon ni ni-user-fill"></em>{' '}
					{ticket.assignedTo}
				</div>
			</td>
			<td className="tb-ticket-status">
				<Badge
					text={ticket.status}
					type={ticket.status == 'opened' ? 'success' : 'danger'}
				/>
			</td>
			<td className="tb-ticket-action">
				<Link to={DETAILS(ticket.id)} className="btn btn-icon btn-trigger">
					<em className="icon ni ni-chevron-right"></em>
				</Link>
			</td>
		</tr>
	);
}

export default TicketTableRow;
