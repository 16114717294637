import { useQuery } from 'react-query';

import { APP_USING_MOCKS } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { contactMockRepository } from '../mocks';
import { ContactName } from '../types';

export const getAllContactNames = (): Promise<ContactName[]> => {
	if (APP_USING_MOCKS) {
		return contactMockRepository.getAllContactNames();
	}
	return axios.get('/contact/all-names');
};

type QueryFnType = typeof getAllContactNames;

type UseAllContactNames = {
	config?: QueryConfig<QueryFnType>;
};

export const useAllContactNames = ({ config }: UseAllContactNames = {}) => {
	return useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: ['contacts-names'],
		queryFn: () => getAllContactNames(),
	});
};
