import { APP_NAME } from '@/config';

export const Footer = () => {
	return (
		<div className="nk-footer">
			<div className="container-fluid">
				<div className="nk-footer-wrap">
					<div className="nk-footer-copyright">
						{' '}
						&copy; 2022 {APP_NAME}. Hosted by{' '}
						<a rel="noopener noreferrer" href="https://neo-tic.com" target="_blank">
							NEOTIC
						</a>
					</div>
					<div className="nk-footer-links">
						<a href="">
							<em className="icon ni ni-globe text-base"></em>
							<span className="text-base">Français</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
