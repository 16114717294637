import clsx from 'clsx';
import React from 'react';

import { BlockHeadContent } from './BlockHeadContent';

type Size = 'sm' | 'md' | 'lg' | 'xl';

type BlockHeadProps = {
	wide?: Size;
	size?: Size;
	className?: string;
	children: React.ReactNode;
};

export const BlockHead = ({ wide, size, className, children }: BlockHeadProps) => {
	return (
		<div
			className={clsx(
				'nk-block-head',
				size && `nk-block-head-${size}`,
				wide && `wide-${size}`,
				className
			)}
		>
			<BlockHeadContent>{children}</BlockHeadContent>
		</div>
	);
};
