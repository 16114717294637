import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigateOptions, useNavigate } from 'react-router-dom';

import { useGoBack } from '@/hooks';

import { ButtonLink } from './ButtonLink';

type BackButtonProps = {
	to?: string;
	options?: NavigateOptions;
};

export const BackButton = ({ to, options }: BackButtonProps) => {
	const goBack = useGoBack();
	const navigate = useNavigate();

	const handleGoBack = () => {
		if (to) {
			navigate(to, options);
		} else {
			goBack();
		}
	};

	return (
		<ButtonLink className="back-to" onClick={handleGoBack}>
			<FontAwesomeIcon icon={faArrowLeftLong} /> Retour à la liste
		</ButtonLink>
	);
};
