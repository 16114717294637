import { useNavigate } from 'react-router-dom';

import { useNotificationStore } from '@/stores/notifications';

import { AuthLayout, SignInForm } from '../components';

function SignIn() {
	const navigate = useNavigate();
	const { addNotification } = useNotificationStore();

	const handleSuccess = () => {
		navigate('/app');
		addNotification({
			title: 'Success!',
			type: 'success',
			message: 'Welcome back!',
		});
	};

	return (
		<AuthLayout title="Connexion" subtitle="Connectez-vous pour accéder à votre compte.">
			<SignInForm onSuccess={handleSuccess} />
		</AuthLayout>
	);
}

export default SignIn;
