import { Spinner as BsSpinner, SpinnerProps as BsSpinnerProps } from 'react-bootstrap';

type SpinnerProps = Omit<Omit<BsSpinnerProps, 'animation'>, 'size'> & {
	animation?: 'border' | 'grow';
	size?: 'sm';
};

export const Spinner = ({ animation = 'border', size = 'sm', ...props }: SpinnerProps) => {
	return <BsSpinner {...{ animation, size, ...props }} />;
};
