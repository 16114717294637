import { Helmet } from 'react-helmet-async';

import { APP_NAME } from '@/config';

type HeadProps = {
	title?: string;
	description?: string;
};

export const Head = ({ title = '', description = '' }: HeadProps = {}) => {
	return (
		<Helmet
			title={title ? `${title} | ${APP_NAME}` : APP_NAME}
			defaultTitle="Bulletproof React"
		>
			<meta name="description" content={description} />
		</Helmet>
	);
};
