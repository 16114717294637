import { ContactName } from '../types';

import contacts from './contacts.json';

export function getAllContactNames(): Promise<ContactName[]> {
	return new Promise((resolve) => {
		setTimeout(() => {
			const names: ContactName[] = contacts.map((contact) => ({
				id: contact.id,
				name: contact.name,
			}));

			resolve(names);
		}, 0.1);
	});
}
