import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, Route, Routes } from 'react-router-dom';

import { ErrorFallback, Spinner } from '@/components/Elements';
import { Head } from '@/components/Head';
import { AppLayout } from '@/components/Layouts';

import { Sidebar } from '../components/Sidebar';
import { ContactNotes } from '../features';

import { Contact } from './Contact';
import { ContactActivity } from './ContactActivity';
import { ContactDocuments } from './ContactDocuments';
import { ContactMessages } from './ContactMessages';
import { Contacts } from './Contacts';
import { CreateContact } from './CreateContact';
import { Dashboard } from './Dashboard';
import { EditContact } from './EditContact';

const prefix = '/app/contacts';

export const CONTACT_ROUTES = {
	dashboard: prefix,
	list: `${prefix}/list`,
	create: `${prefix}/create`,
	show: `${prefix}/list/:id`,
	edit: `${prefix}/list/:id/edit`,
	import: `${prefix}/import`,
	import_copy_past: `${prefix}/import-copy-past`,
	import_upload: `${prefix}/import-upload`,
	notes: `${prefix}/list/:id/notes`,
	messages: `${prefix}/list/:id/messages`,
	documents: `${prefix}/list/:id/documents`,
};

const App = () => {
	return (
		<Suspense fallback={<Spinner />}>
			<Head title="Contacts" />
			<AppLayout>
				<Sidebar />
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Outlet />
				</ErrorBoundary>
			</AppLayout>
		</Suspense>
	);
};

export const ContactsRoutes = () => {
	return (
		<Routes>
			<Route path="" element={<App />}>
				<Route path="list" element={<Contacts />} />
				<Route path="create" element={<CreateContact />} />
				<Route path="list/:id" element={<Contact />}>
					<Route path="" element={<ContactActivity />} />
					<Route path="documents" element={<ContactDocuments />} />
					<Route path="messages" element={<ContactMessages />} />
					<Route path="notes" element={<ContactNotes />} />
				</Route>
				<Route path="list/:id/edit" element={<EditContact />} />
				<Route path="" element={<Dashboard />} />
			</Route>
		</Routes>
	);
};
