import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import SignIn from './SignIn';

export const AuthRoutes = () => {
	useEffect(() => {
		document.body.classList.remove('npc-default', 'has-apps-sidebar', 'has-sidebar');
		document.body.classList.add('bg-white', 'pg-auth');
	}, []);

	return (
		<Routes>
			<Route path="signin" element={<SignIn />} />
		</Routes>
	);
};
