import { faContactBook, faFileInvoiceDollar, faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@/lib/auth';
import { ROUTES } from '@/routes/protected';

import { Link, Spinner } from '../Elements';

function UserAvatar() {
	const { user, isLoggingIn } = useAuth();

	if (isLoggingIn) {
		return <Spinner />;
	}

	return (
		<div className="user-toggle">
			<div className="user-avatar sm">
				<em className="icon ni ni-user-alt"></em>
			</div>
			<div className="user-info d-none d-md-block">
				<div className="user-status">Administrator</div>
				<div className="user-name dropdown-indicator">{user?.name}</div>
			</div>
		</div>
	);
}

export const Header = () => {
	const { logout, user } = useAuth();

	const { pathname } = useLocation();

	const menuItemClass = (path: string) =>
		pathname.startsWith(path) ? 'nk-menu-item active' : 'nk-menu-item';

	const handleSignout = () => {
		logout();
	};

	return (
		<div className="nk-header nk-header-fixed is-light">
			<div className="container-fluid">
				<div className="nk-header-wrap">
					<div className="nk-menu-trigger d-xl-none ml-n1">
						<Link
							to="#"
							className="nk-nav-toggle nk-quick-nav-icon"
							data-target="sidebarMenu"
						>
							<em className="icon ni ni-menu"></em>
						</Link>
					</div>
					<div className="nk-header-app-name">
						{/* <div className="nk-header-app-logo">
              <em className="icon ni ni-dashlite bg-purple-dim"></em>
            </div> */}
						<div className="nk-header-app-info">
							<Link to={'/app'}>
								<span className="h4">NEOCRM</span>
								<span className="sub-text">BY NEOTIC</span>
							</Link>
						</div>
					</div>
					<div className="nk-header-menu is-light">
						<div className="nk-header-menu-inner">
							<ul className="nk-menu nk-menu-main">
								<li className={menuItemClass(ROUTES.CONTACTS)}>
									<Link
										to={ROUTES.CONTACTS}
										className="nk-menu-link"
										data-original-title=""
										title=""
									>
										<span className="nk-menu-text">
											<FontAwesomeIcon icon={faContactBook} /> Contacts
										</span>
									</Link>
								</li>
								<li className={menuItemClass(ROUTES.INBOX)}>
									<Link
										to={ROUTES.INBOX}
										className="nk-menu-link"
										data-original-title=""
										title=""
									>
										<span className="nk-menu-text">
											<FontAwesomeIcon icon={faInbox} /> Inbox
										</span>
									</Link>
								</li>
								<li className={menuItemClass(ROUTES.INVOICING)}>
									<Link
										to={ROUTES.INVOICING}
										className="nk-menu-link"
										data-original-title=""
										title=""
									>
										<span className="nk-menu-text">
											<FontAwesomeIcon icon={faFileInvoiceDollar} />{' '}
											Facturation
										</span>
									</Link>
								</li>
								<li className={menuItemClass(ROUTES.PROJECTS)}>
									<Link
										to={ROUTES.PROJECTS}
										className="nk-menu-link"
										data-original-title=""
										title=""
									>
										<span className="nk-menu-text">
											<em className="icon ni ni-grid-fill-c"></em> Projets
										</span>
									</Link>
								</li>
								<li className={menuItemClass(ROUTES.TICKETS)}>
									<Link
										to={ROUTES.TICKETS}
										className="nk-menu-link"
										data-original-title=""
										title=""
									>
										<span className="nk-menu-text">
											<em className="icon ni ni-ticket-fill"></em> Tickets
										</span>
									</Link>
								</li>
								{/* <li className={menuItemClass(routes.TICKET_PREFIX)}>
                  <Link to={routes.TICKET_PREFIX} className="nk-menu-link" data-original-title="" title="">
                    <span className="nk-menu-text">
                    <FontAwesomeIcon icon={faTicketAlt} /> Tickets
                    </span>
                  </Link>
                </li> */}
							</ul>
						</div>
					</div>
					<div className="nk-header-tools">
						<ul className="nk-quick-nav">
							<Dropdown className="dropdown language-dropdown d-none d-sm-block mr-3">
								<Dropdown.Toggle as={'a'} className="nk-quick-nav-icon">
									<div className="quick-icon border border-light">
										<img className="icon" src="/english.png" alt="" />
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-menu-right dropdown-menu-s1">
									<ul className="language-list">
										<li>
											<Link to="#" className="language-item">
												<img
													src="/english.png"
													alt=""
													className="language-flag"
												/>
												<span className="language-name">English</span>
											</Link>
										</li>
										<li>
											<Link to="#" className="language-item">
												<img
													src="/french.png"
													alt=""
													className="language-flag"
												/>
												<span className="language-name">Français</span>
											</Link>
										</li>
									</ul>
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown className="user-dropdown">
								<Dropdown.Toggle as={'a'}>
									<UserAvatar />
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
									<div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
										<div className="user-card">
											<div className="user-avatar">
												<span>AB</span>
											</div>
											<div className="user-info">
												<span className="lead-text">{user?.name}</span>
												<span className="sub-text">{user?.email}</span>
											</div>
										</div>
									</div>
									<div className="dropdown-inner">
										<ul className="link-list">
											<li>
												<Link to="">
													<em className="icon ni ni-user-alt"></em>
													<span>View Profile</span>
												</Link>
											</li>
											<li>
												<Link to="">
													<em className="icon ni ni-setting-alt"></em>
													<span>Account Setting</span>
												</Link>
											</li>
											<li>
												<Link to="">
													<em className="icon ni ni-activity-alt"></em>
													<span>Login Activity</span>
												</Link>
											</li>
											<li>
												<Link className="dark-switch" to="#">
													<em className="icon ni ni-moon"></em>
													<span>Dark Mode</span>
												</Link>
											</li>
										</ul>
									</div>
									<div className="dropdown-inner">
										<ul className="link-list">
											<li>
												<Link to="#" onClick={handleSignout}>
													<em className="icon ni ni-signout"></em>
													<span>Sign out</span>
												</Link>
											</li>
										</ul>
									</div>
								</Dropdown.Menu>
							</Dropdown>
							<Dropdown className="notification-dropdown ml-3">
								<Dropdown.Toggle as={'a'} className="nk-quick-nav-icon">
									<div className="icon-status icon-status-info">
										<em className="icon ni ni-bell"></em>
									</div>
								</Dropdown.Toggle>
								<Dropdown.Menu className="dropdown-menu-xl dropdown-menu-right dropdown-menu-s1">
									<div className="dropdown-head">
										<span className="sub-title nk-dropdown-title">
											Notifications
										</span>
										<Link to="#">Mark All as Read</Link>
									</div>
									<div className="dropdown-body">
										<div className="nk-notification">
											<div className="nk-notification-item dropdown-inner">
												<div className="nk-notification-icon">
													<em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
												</div>
												<div className="nk-notification-content">
													<div className="nk-notification-text">
														You have requested to{' '}
														<span>Widthdrawl</span>
													</div>
													<div className="nk-notification-time">
														2 hrs ago
													</div>
												</div>
											</div>
											<div className="nk-notification-item dropdown-inner">
												<div className="nk-notification-icon">
													<em className="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
												</div>
												<div className="nk-notification-content">
													<div className="nk-notification-text">
														Your <span>Deposit Order</span> is placed
													</div>
													<div className="nk-notification-time">
														2 hrs ago
													</div>
												</div>
											</div>
											<div className="nk-notification-item dropdown-inner">
												<div className="nk-notification-icon">
													<em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
												</div>
												<div className="nk-notification-content">
													<div className="nk-notification-text">
														You have requested to{' '}
														<span>Widthdrawl</span>
													</div>
													<div className="nk-notification-time">
														2 hrs ago
													</div>
												</div>
											</div>
											<div className="nk-notification-item dropdown-inner">
												<div className="nk-notification-icon">
													<em className="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
												</div>
												<div className="nk-notification-content">
													<div className="nk-notification-text">
														Your <span>Deposit Order</span> is placed
													</div>
													<div className="nk-notification-time">
														2 hrs ago
													</div>
												</div>
											</div>
											<div className="nk-notification-item dropdown-inner">
												<div className="nk-notification-icon">
													<em className="icon icon-circle bg-warning-dim ni ni-curve-down-right"></em>
												</div>
												<div className="nk-notification-content">
													<div className="nk-notification-text">
														You have requested to{' '}
														<span>Widthdrawl</span>
													</div>
													<div className="nk-notification-time">
														2 hrs ago
													</div>
												</div>
											</div>
											<div className="nk-notification-item dropdown-inner">
												<div className="nk-notification-icon">
													<em className="icon icon-circle bg-success-dim ni ni-curve-down-left"></em>
												</div>
												<div className="nk-notification-content">
													<div className="nk-notification-text">
														Your <span>Deposit Order</span> is placed
													</div>
													<div className="nk-notification-time">
														2 hrs ago
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="dropdown-foot center">
										<Link to="#">View All</Link>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
