import { useRoutes } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

import { authRoutes } from './auth';
import { commonRoutes } from './common';
import { protectedRoutes } from './protected';

export const AppRoutes = () => {
	const auth = useAuth();

	const routes = auth.user ? protectedRoutes : authRoutes;

	const element = useRoutes([...routes, ...commonRoutes]);

	return <>{element}</>;
};
