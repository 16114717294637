import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { Button, ButtonLink } from '@/components/Elements';

import { useAddNote } from '../api/addNote';
import { ContactNote } from '../types';

interface ContactNoteFormProps {
	showForm: boolean;
	onHideForm: () => void;
	contact: string;
	onAdd: (note: ContactNote) => void;
}

const validationSchema = Yup.object({
	text: Yup.string().required('Ce champs est obligatoire.'),
});

function ContactNoteForm({ showForm, contact, onHideForm, onAdd }: ContactNoteFormProps) {
	const addNoteMutation = useAddNote();

	const handleAdd = (text: string) => {
		addNoteMutation
			.mutateAsync({
				note: {
					contact,
					text,
				},
			})
			.then((note) => {
				if (note.id) {
					onAdd(note);
				}
				onHideForm();
			});
	};

	const { isValid, values, handleChange, resetForm, handleSubmit } = useFormik<{ text: string }>({
		initialValues: { text: '' },
		validationSchema,
		onSubmit: () => {
			handleAdd(values.text);
			resetForm();
		},
	});

	return (
		<div className={showForm ? 'mb-4' : 'd-none mb-4'}>
			<div className="form-group">
				<div className="form-control-wrap">
					<textarea
						value={values.text}
						onChange={handleChange('text')}
						className="form-control form-control-sm"
						placeholder="Rédigez votre note..."
					></textarea>
				</div>
			</div>
			<div className="d-flex justify-between">
				<Button
					disabled={!isValid || addNoteMutation.isLoading}
					onClick={handleSubmit}
					size="sm"
				>
					<em className="ni ni-done"></em>
					Enregistrer la note
				</Button>
				<ButtonLink type="dark" onClick={onHideForm}>
					<em className="ni ni-na"></em>
					Annuler
				</ButtonLink>
			</div>
		</div>
	);
}

export default ContactNoteForm;
