import { Link } from '@/components/Elements';
import { SidebarWrapper } from '@/components/Layouts';

import { TICKET_ROUTES } from '../routes';

export const Sidebar = () => {
	return (
		<SidebarWrapper>
			<li className="nk-menu-item">
				<Link to={TICKET_ROUTES.dashboard} className="nk-menu-link">
					<span className="nk-menu-icon">
						<em className="icon ni ni-home-fill"></em>
					</span>
					<span className="nk-menu-text">Tableau de bord</span>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={TICKET_ROUTES.list} className="nk-menu-link">
					<span className="nk-menu-icon">
						<em className="icon ni ni-ticket-fill"></em>
					</span>
					<span className="nk-menu-text">Tickets</span>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={TICKET_ROUTES.list} className="nk-menu-link">
					<span className="nk-menu-icon">
						<em className="icon ni ni-growth-fill"></em>
					</span>
					<span className="nk-menu-text">Statistiques</span>
				</Link>
			</li>
			<li className="nk-menu-item">
				<Link to={TICKET_ROUTES.list} className="nk-menu-link">
					<span className="nk-menu-icon">
						<em className="icon ni ni-setting-alt-fill"></em>
					</span>
					<span className="nk-menu-text">Paramètres</span>
				</Link>
			</li>
		</SidebarWrapper>
	);
};
