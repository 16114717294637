import React from 'react';

import { Link } from '../Elements';

type BlockToolItemProps = {
	to: string;
	className?: string;
	children: React.ReactNode;
};

export const BlockToolItem = ({ to, className, children }: BlockToolItemProps) => {
	return (
		<li>
			<Link to={to} className={className}>
				{children}
			</Link>
		</li>
	);
};
