import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet } from 'react-router-dom';

import '@/assets/scss/app.scss';
import '@/assets/scss/theme.scss';

import { ErrorFallback, Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layouts';
import { useAppPrefix } from '@/hooks';
import { CONTACT_ROUTES } from '@/modules/contacts';
import { INVOICING_ROUTES } from '@/modules/invoicing';
import { Dashboard } from '@/modules/misc';
import { TICKET_ROUTES } from '@/modules/tickets';
import { lazyImport } from '@/utils/lazyImport';

const { ContactsRoutes } = lazyImport(() => import('@/modules/contacts'), 'ContactsRoutes');
const { TicketsRoutes } = lazyImport(() => import('@/modules/tickets'), 'TicketsRoutes');
const { InvoicingRoutes } = lazyImport(() => import('@/modules/invoicing'), 'InvoicingRoutes');

const MODULES_WITH_SIDEBAR = ['contacts', 'projects', 'invoicing', 'tickets'];
const TOGGLABLE_CLASS_LIST = ['npc-default', 'has-apps-sidebar', 'has-sidebar'];

const App = () => {
	const prefix = useAppPrefix();

	useEffect(() => {
		if (MODULES_WITH_SIDEBAR.includes(prefix || '')) {
			addBodyClass(...TOGGLABLE_CLASS_LIST);
			removeBodyClass('pt-5', 'mt-5');
		} else {
			removeBodyClass(...TOGGLABLE_CLASS_LIST);
			addBodyClass('pt-5', 'mt-5');
		}
	}, [prefix]);

	return (
		<MainLayout>
			<Suspense fallback={<Spinner />}>
				<ErrorBoundary FallbackComponent={ErrorFallback}>
					<Outlet />
				</ErrorBoundary>
			</Suspense>
		</MainLayout>
	);
};

export const ROUTES = {
	CONTACTS: CONTACT_ROUTES.dashboard,
	INBOX: '/app/inbox',
	PROJECTS: '/app/projects',
	INVOICING: INVOICING_ROUTES.dashboard,
	TICKETS: TICKET_ROUTES.dashboard,
};

export const protectedRoutes = [
	{
		path: '/app',
		element: <App />,
		children: [
			{ path: '', element: <Dashboard /> },
			{ path: `${ROUTES.CONTACTS}/*`, element: <ContactsRoutes /> },
			{ path: `${ROUTES.TICKETS}/*`, element: <TicketsRoutes /> },
			{ path: `${ROUTES.INVOICING}/*`, element: <InvoicingRoutes /> },
			{ path: '*', element: <Navigate to={'.'} /> },
		],
	},
	{
		path: '*',
		element: <Navigate to={'/app'} />,
	},
];

function addBodyClass(...tokens: string[]): void {
	document.body.classList.add(...tokens);
}

function removeBodyClass(...tokens: string[]): void {
	document.body.classList.remove(...tokens);
}
