import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSearchParams } from 'react-router-dom';

import {
	BlockHeadBetween,
	BlockHeadContent,
	BlockText,
	BlockTitle,
	BlockToolItem,
	BlockTools,
} from '@/components/Block';
import { Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layouts';

import { useEstimates } from '../api/getEstimates';
import { EstimatesList } from '../components';

import { INVOICING_ROUTES } from '.';

const ITEMS_PER_PAGE = 30;

export const Estimates = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1', 10));
	const [pageCount, setPageCount] = useState<number>(0);

	const { isLoading, data: estimates } = useEstimates({ page });

	useEffect(() => {
		if (estimates?.['hydra:totalItems']) {
			setPageCount(Math.ceil(estimates?.['hydra:totalItems'] / ITEMS_PER_PAGE));
			window.scrollTo({ behavior: 'smooth', top: 0 });
		}
	}, [estimates]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const next = ++selected;
		setPage(next);
		setSearchParams({ page: next.toString() });
	};

	return (
		<ContentLayout title="Estimates">
			<BlockHeadBetween>
				<BlockHeadContent>
					<BlockTitle>
						<span className="nk-menu-icon">
							<em className="icon ni ni-estimate-fill"></em>
						</span>
						Devis
					</BlockTitle>
					<BlockText className="text-soft">
						{estimates?.['hydra:totalItems'] && (
							<p>Vous avez au total {estimates['hydra:totalItems']} devis.</p>
						)}
					</BlockText>
				</BlockHeadContent>
				<BlockHeadContent>
					<BlockTools>
						<BlockToolItem
							to={INVOICING_ROUTES.estimate_new}
							className="btn btn-primary"
						>
							<em className="icon ni ni-estimate-plus"></em>
							<span>Créer un devis</span>
						</BlockToolItem>
					</BlockTools>
				</BlockHeadContent>
			</BlockHeadBetween>
			<div className="nk-block">
				<div className="card card-bordered card-stretch">
					<div className="card-inner-group">
						<div className="card-inner">
							<div className="card-title-group">
								<div className="card-title">
									<h5 className="title">Tous les devis</h5>
								</div>
								<div className="card-tools mr-n1">
									<ul className="btn-toolbar">
										<li>
											<a
												href="#"
												className="btn btn-icon search-toggle toggle-search"
												data-target="search"
											>
												<em className="icon ni ni-search"></em>
											</a>
										</li>
										<li className="btn-toolbar-sep"></li>
										<li>
											<div className="dropdown">
												<a
													href="#"
													className="btn btn-trigger btn-icon dropdown-toggle"
													data-toggle="dropdown"
												>
													<em className="icon ni ni-setting"></em>
												</a>
												<div className="dropdown-menu dropdown-menu-right dropdown-menu-xs">
													<ul className="link-check">
														<li>
															<span>Show</span>
														</li>
														<li className="active">
															<a href="#">10</a>
														</li>
														<li>
															<a href="#">20</a>
														</li>
														<li>
															<a href="#">50</a>
														</li>
													</ul>
													<ul className="link-check">
														<li>
															<span>Order</span>
														</li>
														<li className="active">
															<a href="#">DESC</a>
														</li>
														<li>
															<a href="#">ASC</a>
														</li>
													</ul>
													<ul className="link-check">
														<li>
															<span>Density</span>
														</li>
														<li className="active">
															<a href="#">Regular</a>
														</li>
														<li>
															<a href="#">Compact</a>
														</li>
													</ul>
												</div>
											</div>
										</li>
									</ul>
								</div>
								<div className="card-search search-wrap" data-search="search">
									<div className="search-content">
										<a
											href="#"
											className="search-back btn btn-icon toggle-search"
											data-target="search"
										>
											<em className="icon ni ni-arrow-left"></em>
										</a>
										<button className="search-submit btn btn-icon">
											<em className="icon ni ni-search"></em>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="card-inner p-0">
							<EstimatesList estimates={estimates?.['hydra:member'] || []} />
						</div>
						<div className="card-inner w-100">
							<div className="text-center" style={{ height: 75, paddingTop: 25 }}>
								{isLoading && <Spinner />}
								{!isLoading && !estimates?.['hydra:totalItems'] && (
									<p className="text-body">Cette liste est vide.</p>
								)}
							</div>
						</div>
						<div className="card-inner">
							<div className="nk-block-between-md g-3">
								<ReactPaginate
									initialPage={page - 1}
									pageCount={pageCount}
									onPageChange={handlePageClick}
									activeClassName="active"
									pageClassName="page-item"
									pageLinkClassName="page-link"
									containerClassName="pagination justify-content-center"
									breakClassName="page-item"
									breakLinkClassName="page-link"
									breakLabel={<em className="icon ni ni-more-h"></em>}
									nextClassName="page-item"
									nextLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ContentLayout>
	);
};
