import clsx from 'clsx';
import React from 'react';

type BlockHeadContentProps = {
	className?: string;
	children: React.ReactNode;
};

export const BlockHeadContent = ({ className, children }: BlockHeadContentProps) => {
	return <div className={clsx('nk-block-head-content', className)}>{children}</div>;
};
