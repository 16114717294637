import { BlockHead, BlockText, BlockTitle } from '@/components/Block';
import { CardLink } from '@/components/Card';
import { AppLayout, ContentLayout } from '@/components/Layouts';
import { CONTACT_ROUTES } from '@/modules/contacts';
import { INVOICING_ROUTES } from '@/modules/invoicing';
import { TICKET_ROUTES } from '@/modules/tickets';

const modules: ModuleItemProps[] = [
	{
		title: 'CRM',
		description:
			'Outil utilisé pour la gestion des contacts, des ventes, la productivité et bien plus encore.',
		path: CONTACT_ROUTES.dashboard,
	},
	{
		title: 'Gestion de tickets',
		description:
			'Convertir toutes les demandes de support entrantes provenant de différents canaux en tickets de façon optimale et transparente.',
		path: TICKET_ROUTES.dashboard,
	},
	{
		title: 'Facturation',
		description:
			'Établissez des devis, générez des factures, créez des relances automatiques et bien plus!',
		path: INVOICING_ROUTES.dashboard,
	},
	{
		title: 'Gestion de projet',
		description:
			"Organisez sous forme d'un logiciel la bonne conduite de votre projet en encadrant le suivi de la méthodologie...",
		path: '/app/projects',
	},
];

export const Dashboard = () => {
	return (
		<AppLayout>
			<ContentLayout title="Tableau de bord">
				<div className="container">
					<BlockHead size="sm">
						<BlockTitle>Choisir un module</BlockTitle>
						<BlockText className="lead">
							Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloremque
							perferendis, pariatur sed vel ea recusandae! Harum voluptates debitis,
							est necessitatibus nam voluptas cupiditate, odio veritatis ducimus
							nobis, ex iste blanditiis.
						</BlockText>
					</BlockHead>
					<div className="row g-gs">
						{modules.map((module, index) => (
							<ModuleItem key={index} {...module} />
						))}
					</div>
				</div>
			</ContentLayout>
		</AppLayout>
	);
};

type ModuleItemProps = {
	title: string;
	description?: string;
	path: string;
};

const ModuleItem = ({ title, description, path }: ModuleItemProps) => {
	return (
		<div className="col-lg-6">
			<CardLink bordered to={path} className="text-soft h-125px">
				<div className="align-center justify-between">
					<div className="g">
						<h6 className="title">{title}</h6>
						{description && <p>{description}</p>}
					</div>
					<div className="g">
						<span className="btn btn-icon btn-trigger mr-n1">
							<em className="icon ni ni-chevron-right"></em>
						</span>
					</div>
				</div>
			</CardLink>
		</div>
	);
};
