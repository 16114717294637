import { Link } from '@/components/Elements';

import { Estimate as iEstimate } from '../types';

import Estimate from './Estimate';

interface EstimatesListProps {
	estimates: iEstimate[];
}

function EstimatesList({ estimates }: EstimatesListProps) {
	return (
		<table className="table table-orders">
			<thead className="tb-odr-head">
				<tr className="tb-odr-item">
					<th className="tb-odr-info">
						<span className="tb-odr-id">Réference</span>
						<span className="tb-odr-date d-none d-md-inline-block">
							Date de création
						</span>
					</th>
					<th className="tb-odr-customer">Client</th>
					<th className="tb-odr-amount">
						<span className="tb-odr-total">Montant</span>
						<span className="tb-odr-status d-none d-md-inline-block">Statut</span>
					</th>
					<th className="tb-odr-action">&nbsp;</th>
				</tr>
			</thead>
			<tbody className="tb-odr-body">
				{estimates?.map((e) => (
					<Estimate key={`e${e.id}-${e.uid}`} estimate={e} />
				))}
			</tbody>
		</table>
	);
}

export default EstimatesList;
