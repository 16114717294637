import clsx from 'clsx';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
	label: React.ReactNode;
	value: string | number | string[];
};

export type SelectFieldProps = FieldWrapperPassThroughProps & {
	options: Option[];
	defaultValue?: string;
	controlSize?: 'sm' | 'md' | 'lg';
	className?: string;
	placeholder?: string;
	registration: Partial<UseFormRegisterReturn>;
};

export const SelectField = (props: SelectFieldProps) => {
	const {
		options,
		defaultValue,
		className,
		controlSize = 'lg',
		placeholder,
		registration,
		...wrapperProps
	} = props;
	return (
		<FieldWrapper {...wrapperProps}>
			<select
				className={clsx(
					'form-control',
					controlSize && `form-control-${controlSize}`,
					className
				)}
				{...{ placeholder, defaultValue, ...registration }}
			>
				<option value="">{placeholder}</option>
				{options.map(({ label, value }) => (
					<option key={label?.toString()} value={value}>
						{label}
					</option>
				))}
			</select>
		</FieldWrapper>
	);
};
