import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
	className?: string;
	placeholder?: string;
	registration: Partial<UseFormRegisterReturn>;
};

export const TextAreaField = (props: TextAreaFieldProps) => {
	const { htmlFor, placeholder, className, registration, ...wrapperProps } = props;
	return (
		<FieldWrapper {...wrapperProps}>
			<textarea
				className={clsx('form-control', className)}
				id={htmlFor}
				placeholder={placeholder}
				{...registration}
			/>
		</FieldWrapper>
	);
};
