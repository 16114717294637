import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { BaseEntity } from '@/types';

import { useCreate, useEdit, useItem, useList } from '../api';

type ItemParams = {
	id: string;
};

export function useDetails<Item>(path: string) {
	const { id } = useParams<ItemParams>() as ItemParams;
	const query = useItem<Item>({ id, path });

	return { id, ...query };
}

const ITEMS_PER_PAGE = 30;

export function useListing<Item>(path: string) {
	const [searchParams, setSearchParams] = useSearchParams();
	const [page, setPage] = useState<number>(parseInt(searchParams.get('page') || '1', 10));
	const [pageCount, setPageCount] = useState<number>(0);

	const { data, ...query } = useList<Item>({ page, path });

	useEffect(() => {
		if (data?.['hydra:totalItems']) {
			setPageCount(Math.ceil(data?.['hydra:totalItems'] / ITEMS_PER_PAGE));
			window.scrollTo({ behavior: 'smooth', top: 0 });
		}
	}, [data]);

	const handlePageClick = ({ selected }: { selected: number }) => {
		const next = ++selected;
		setPage(next);
		setSearchParams({ page: next.toString() });
	};

	return { data, page, pageCount, handlePageClick, ...query };
}

type CreateItemParams = {
	key: string;
	successMessage: string;
};

export function useCreateItem<Type, TypeDTO>(params: CreateItemParams) {
	const createMutation = useCreate<Type, TypeDTO>(params);

	return createMutation;
}

export function useUpdateItem<Type extends BaseEntity, TypeDTO>(params: CreateItemParams) {
	const createMutation = useEdit<Type, TypeDTO>(params);

	return createMutation;
}
