import { ReactNode } from 'react';

type SidebarWrapperProps = {
	children: ReactNode;
};

export const SidebarWrapper = ({ children }: SidebarWrapperProps) => {
	return (
		<div className="nk-sidebar" data-content="sidebarMenu">
			<div className="nk-sidebar-inner">
				<ul className="nk-menu nk-menu-md">{children}</ul>
			</div>
		</div>
	);
};
