import Axios, { AxiosRequestConfig } from 'axios';
import { setupCache } from 'axios-cache-adapter';

import { API_URL } from '@/config';
import { useNotificationStore } from '@/stores/notifications';
import storage from '@/utils/storage';

export const axios = Axios.create({
	baseURL: API_URL,
	adapter: setupCache({
		maxAge: 1000 * 60,
	}).adapter,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
	(response) => response.data,
	(error) => {
		const message = error.response?.data?.message || error.message;
		useNotificationStore.getState().addNotification({
			type: 'error',
			title: 'Error',
			message,
		});

		return Promise.reject(error);
	}
);

function authRequestInterceptor(config: AxiosRequestConfig) {
	const token = storage.getToken();

	if (!config.headers) {
		config.headers = {};
	}
	config.headers.Accept = 'application/ld+json';
	config.headers['Access-Control-Allow-Origin'] = '*';

	if (token) {
		config.headers['X-AUTH-TOKEN'] = token;
	}

	return config;
}
