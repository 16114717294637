import clsx from 'clsx';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

export type InputFieldProps = FieldWrapperPassThroughProps & {
	type?: 'text' | 'email' | 'password' | 'tel';
	className?: string;
	placeholder?: string;
	registration: Partial<UseFormRegisterReturn>;
	defaultValue?: string | number | readonly string[];
};

export const InputField = (props: InputFieldProps) => {
	const {
		type = 'text',
		className,
		htmlFor,
		placeholder,
		registration,
		defaultValue,
		...wrapperProps
	} = props;

	return (
		<FieldWrapper {...wrapperProps}>
			<input
				type={type}
				className={clsx('form-control form-control-lg', className)}
				id={htmlFor}
				placeholder={placeholder}
				defaultValue={defaultValue}
				{...registration}
			/>
		</FieldWrapper>
	);
};
