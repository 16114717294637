import { Suspense } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { Landing } from '@/modules/misc';
import storage from '@/utils/storage';

const App = () => {
	return (
		<div className="nk-main">
			<header>
				<h3>
					<Link to={storage.hasToken() ? '/app' : '/auth/signin'}>Application</Link>
				</h3>
			</header>
			<Suspense fallback={<Spinner />}>
				<Outlet />
			</Suspense>
		</div>
	);
};

export const commonRoutes = [
	{
		path: '/',
		element: <App />,
		children: [{ path: '/', element: <Landing /> }],
	},
];
