import React from 'react';
import * as z from 'zod';

import { Button, Spinner } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { SignInIcon } from '@/components/Icons';
import { useAuth } from '@/lib/auth';

import { SignInCredentialsTDO } from '../api/signin';

const schema = z.object({
	email: z.string().email(),
	password: z.string().min(1, 'Type your password'),
});

type SignInFormProps = {
	onSuccess: () => void;
};

export const SignInForm = ({ onSuccess }: SignInFormProps) => {
	const { login, isLoggingIn } = useAuth();

	return (
		<Form<SignInCredentialsTDO, typeof schema>
			onSubmit={async (values) => {
				await login(values);
				onSuccess();
			}}
			schema={schema}
		>
			{({ register, formState }) => (
				<>
					<InputField
						type="email"
						label="Adresse e-mail"
						placeholder="Entrez votre adresse e-mail"
						error={formState.errors['email']}
						registration={register('email')}
					/>
					<InputField
						type="password"
						label="Mot de passe"
						placeholder="Entrez votre mot de passe"
						error={formState.errors['password']}
						registration={register('password')}
					/>
					<Button
						disabled={isLoggingIn}
						size="lg"
						variant="primary"
						type="submit"
						className="btn btn-block"
					>
						{isLoggingIn ? <Spinner animation="grow" size="sm" /> : <SignInIcon />}
						<span>Connexion</span>
					</Button>
				</>
			)}
		</Form>
	);
};
