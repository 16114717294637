import { axios } from '@/lib/axios';

import { UserResponse } from '../types';

export type SignInCredentialsTDO = {
	email: string;
	password: string;
};

export function signin(data: SignInCredentialsTDO): Promise<UserResponse> {
	return axios.post('/login', data);
}
