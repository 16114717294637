import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { PartialCollectionResults } from '@/types';

import { ContactNote } from '../types';

export const getContactNotes = ({ contactId }: { contactId: string }): Promise<ContactNote[]> => {
	return axios
		.get(`contact/notes?contact=${contactId}`)
		.then((res: AxiosResponse<PartialCollectionResults<ContactNote>>) => {
			const data = res as any as PartialCollectionResults<ContactNote>;
			return data['hydra:member'];
		});
};

type QueryFnType = typeof getContactNotes;

type UseContactNotes = {
	config?: QueryConfig<QueryFnType>;
	contactId: string;
};

export const useContactNotes = ({ config, contactId }: UseContactNotes) => {
	return useQuery<ExtractFnReturnType<QueryFnType>>({
		...config,
		queryKey: ['contacts-notes', contactId],
		queryFn: () => getContactNotes({ contactId }),
	});
};
